import {MarkdownLink, markdownRenderers} from "./markdown";
import crosslink from "./crosslink";
import ReactMarkdown from "react-markdown";
import nl2br from "react-nl2br";
const gfm = require('remark-gfm');
const breaks = require('remark-breaks');

export const RenderedMarkdown = ({text}) => {
    return <ReactMarkdown escapeHtml={false} renderers={markdownRenderers} plugins={[gfm, crosslink, breaks]} children={text} />
}

export const RenderedText = ({text}) => {
    const nodes = [];
    let matches= text.matchAll(/#\{(?<title>.*?)\}\[(?<id>.*?)\]/ig);
    let cursor = 0;
    for (let match of matches) {
        const {title, id} = match.groups;
        nodes[nodes.length] = nl2br(text.slice(cursor, match.index));
        cursor = match.index + match[0].length;
        nodes[nodes.length] = <MarkdownLink key={nodes.length} url={new URL(`/activity/${id}`, document.baseURI).href} children={title}/>
    }
    nodes[nodes.length] = nl2br(text.slice(cursor, text.length));

    return nodes;
}