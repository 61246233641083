import {Tooltip} from "@material-ui/core";
import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import {grey} from "@material-ui/core/colors";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import {useTranslation} from 'react-i18next';
import {RenderedMarkdown} from "./render";
import MentionField from "./mentions";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Box from "@material-ui/core/Box";

const useStyles = makeStyles( (theme) => ({
    mdPreview: {
        padding: theme.spacing(2),
        backgroundColor: grey[100],
        height: "100%",
        border: 0
    },
    previewToggle: {
        position: "absolute",
        right: 1,
        top: 1,
        width: "auto",
        height: "auto !important",
        zIndex: theme.zIndex.modal + 1,
        backgroundColor: theme.palette.common.white,
        '& button':  {
            borderRadius: 0,
            borderBottomLeftRadius: "3px",
            borderTop: 0,
            borderRight: 0,
            padding: theme.spacing(0.5)
        },
        '& button:last-child':  {
            borderLeft: 0,
        }
    },
}));


const MarkdownField = (props) => {

    const {text, onChange, label, placeholder} = props;
    const [preview, setPreview] = useState(false);
    const classes = useStyles();

    const { t } = useTranslation();

    const handlePreviewToggle = (event, newValue) => {
        setPreview(!!newValue);
    }

    return (<React.Fragment>

        <Box hidden={preview} width="100%">
            <MentionField
                fullWidth
                variant="outlined"
                label={label}
                value={text}
                placeholder={placeholder}
                onChange={onChange}/>
        </Box>
        <Box hidden={!preview} width="100%">
            <Paper variant="outlined" square className={classes.mdPreview}>
                <RenderedMarkdown text={text} />
            </Paper>
        </Box>

        <ToggleButtonGroup value={preview}
                           exclusive
                           size="small"
                           onChange={handlePreviewToggle}
                           className={classes.previewToggle}
                           aria-label="text formatting">
            <ToggleButton value={false} >
                <Tooltip title={t('activityForm.fields.editMarkdown')} placement="top">
                    <EditIcon fontSize="small" />
                </Tooltip>
            </ToggleButton>

            <ToggleButton value={true} >
                <Tooltip title={t('activityForm.fields.previewMarkdown')} placement="top">
                    <VisibilityIcon fontSize="small" />
                </Tooltip>
            </ToggleButton>
        </ToggleButtonGroup>
    </React.Fragment>)
}

export default MarkdownField;