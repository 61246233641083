import React, {useContext, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import {Dialog, DialogContent, DialogTitle, Tab, Tabs} from "@material-ui/core";
import AuthContext from "../../contexts/auth";
import useAxios from "axios-hooks";
import {isValidEmail, setToken} from "../../utils/profile";
import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const SignInDialog = (props) => {

    const {open, setOpen}  = props;
    const { t } = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    return <Dialog
        open={open}
        onClose={handleClose}
        scroll='paper'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
    >
        <DialogTitle id="scroll-dialog-title">
            {t('landing.signin')}
        </DialogTitle>
        <DialogContent>
            <SignIn />
        </DialogContent>

    </Dialog>

}

const SignIn = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation();
    const auth = useContext(AuthContext);
    const classes = useStyles();

    const [{data, loading, response}, doSubmit] = useAxios({
        url: "/api/auth/v1/token",
        method: "POST",
        validateStatus: (status) => status >= 200 && status < 500,
    }, {manual: true})

    const handleSubmit = () => {

        if (isValidEmail(email) && password) {
            doSubmit({
                data: {
                    email: email,
                    password: password,
                }
            })

        }
    }

    useEffect(() => {
        if (response && (response.status === 401 || response.status === 400)) {
            enqueueSnackbar(t("landing.wrongCreds"), {
                variant: "error",
            })
        } else if (response && response.status === 200 && data) {
            setToken(data.token);
            auth.fetch();
        }
    }, [response, data]);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    return <React.Fragment>
        <div className={classes.form}>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                onChange={handleEmailChange}
                autoComplete="email"
                autoFocus
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={handlePasswordChange}
                autoComplete="current-password"
            />
            <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                className={classes.submit}
            >
                {t('landing.doSignin')}
            </Button>
            <Grid container>
                <Grid item xs>
                    <Link href="#" variant="body2">
                        Forgot password?
                    </Link>
                </Grid>
            </Grid>
        </div>
    </React.Fragment>
}

export default SignInDialog;