import {Mention, MentionsInput} from "react-mentions";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {getAxiosHeaders} from "../../api/client";
import Box from "@material-ui/core/Box";
import {BEATS} from "../feed/beats";

const axios = require('axios');

const styled = {
    width: '100%',
    '&multiLine': {
        highlighter: {
            padding: 16,
            border: '0',
            lineHeight: '1.5',
        },
        suggestions: {
            height: 'auto',
            top: '25px',
            maxWidth: 'calc(100% - 16px)',
            marginLeft: '8px',
            zIndex: 1301
        },
        input: {
            width: '100%',
            padding: 16,
            border: '0',
            lineHeight: '1.5',
        },
        outline: 'none'
    },
}

const useStyles = makeStyles((theme) => ({
    textarea: {
        '&__input:focus': {
            outline: 'none'
        },
        '&__suggestions': {
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[6],
            borderSize: '1px',
            borderColor: theme.palette.grey[200],
        }
    },
    mention: {
        backgroundColor: theme.palette.primary.light,
    }
}));

const requestActivities = (query, callback) => {
    if (!query) return
    axios.get('/api/memory/v1/autocomplete', {
        params: {title: query},
        headers: getAxiosHeaders()
    }).then(response => response.data)
        // Transform the users to what react-mentions expects
        .then(res =>
            // res.items.map(item => ({ display: item.title, id: new URL(`/activity/${item.id}`, document.baseURI).href}))
            res.items.map(item => {
                item.display = item.title;
                return item
            })
        )
        .then(callback)
}

const renderActivitySuggestions = (entry, search, highlightedDisplay, index, focused) => {
    return <Box component="span" display="inline-flex" m={1}>
        <Box>{BEATS[entry.type].icon({fontSize: "small"})}</Box>
        <Box pl={2}>{entry.title}</Box>
    </Box>
}

const MentionField = ({value, label, onChange, placeholder}) => {

    const classes = useStyles();

    return  <MentionsInput
        value={value}
        label={label}
        onChange={onChange}
        style={styled}
        placeholder={placeholder}
        className={classes.textarea}
    >
        <Mention
            displayTransform={(id, display) => display || id}
            markup="@{__display__}[__id__]"
            trigger="@"
            className={classes.mention}
            appendSpaceOnAdd={true}
            data={requestActivities}
            renderSuggestion={renderActivitySuggestions}
        />
    </MentionsInput>
}

export default MentionField;