import DescriptionIcon from "@material-ui/icons/Description";
import YouTubeIcon from "@material-ui/icons/YouTube";
import SchoolIcon from "@material-ui/icons/School";
import PeopleIcon from "@material-ui/icons/People";
import HeadsetIcon from '@material-ui/icons/Headset';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import CastForEducationIcon from "@material-ui/icons/CastForEducation";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import {ActivityIcon} from "../../icons";

export const BEATS = {
    "general": {
        key: "general",
        title: "activities.general",
        icon: (props) => <ActivityIcon {...props}/>,
        enabled: false,
        filter: false
    },
    cheatsheet: {
        key: "cheatsheet",
        title: "activities.cheatsheet",
        icon: (props) => <WbIncandescentIcon {...props}/>,
        enabled: true,
        filter: false
    },

    article: {
        key: "article",
        title: "activities.article",
        icon: (props) => <DescriptionIcon {...props}/>,
        enabled: true,
        filter: true
    },
    book: {
        key: "book",
        title: "activities.book",
        icon: (props) => <ImportContactsIcon {...props}/>,
        enabled: true,
        filter: true
    },
    course: {
        key: "course",
        title: "activities.course",
        icon:  (props) => <SchoolIcon {...props}/>,
        enabled: true,
        filter: true
    },
    video: {
        key: "video",
        title: "activities.video",
        icon:  (props) => <YouTubeIcon {...props}/>,
        enabled: true,
        filter: true
    },
    podcast: {
        key: "podcast",
        title: "activities.podcast",
        icon: (props) => <HeadsetIcon {...props}/>,
        enabled: true,
        filter: true
    },
    talk: {
        key: "talk",
        title: "activities.talk",
        icon: (props) => <RecordVoiceOverIcon {...props}/>,
        enabled: true,
        filter: true
    },
    conference: {
        key: "conference",
        title: "activities.conference",
        icon:  (props) => <PeopleIcon {...props}/>,
        enabled: false,
        filter: true
    },
    chapter: {
        key: "chapter",
        title: "activities.chapter",
        icon: (props) => <MenuBookIcon {...props}/>,
        enabled: false,
        filter: true
    },
    lesson: {
        key: "lesson",
        title: "activities.lesson",
        icon: (props) => <CastForEducationIcon {...props}/>,
        enabled: false,
        filter: true
    },
};
