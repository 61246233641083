import React, {useContext, useEffect, useState} from "react";
import useAxios from "axios-hooks";
import { Trans } from "react-i18next";
import {getAxiosHeaders} from "../../api/client";
import {LinearProgress} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AddActionMenu from "./add";
import {Link as RouterLink, useParams, useHistory} from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import {CompactItemLine} from "../heartbeats/compact";
import AuthContext from "../../contexts/auth";

const _ = require("lodash");

const HeartBeat = () => {

    const [heartbeats, setHeartbeats] = useState([]);
    const {activityId} = useParams();
    const history = useHistory();
    const auth = useContext(AuthContext);

    const [{data, loading}] = useAxios({
        url: `/api/memory/v1/heartbeats/${activityId}`,
        headers: getAxiosHeaders()
    });

    useEffect(() => {
        if (data) {
            document.title = `ipsly: ${data.title}`;
            setHeartbeats([data]);
        }
    }, [data]);

    const updateWithoutReload = (item) => {
        setHeartbeats(_.map(heartbeats, (heartbeat) => {
            return heartbeat.id === item.id ?
                _.assign({}, heartbeat, item) :
                heartbeat;
        }));
        auth.fetch();
    }

    return loading ? <LinearProgress />  :
        <div>
            <Button component={RouterLink} to="/feed/behind">
                <ArrowBackIcon /> <Trans>Back to feed</Trans>
            </Button>
            <Grid container spacing={2}>
                {_.map(heartbeats, (item) =>
                    <CompactItemLine
                        key={item.id}
                        item={item}
                        insert={() => {}}
                        update={updateWithoutReload}
                        remove={() => {
                            history.push("/")
                        }}
                        forceCard
                    />
                )}
            </Grid>
            <AddActionMenu />
        </div>
}

export default HeartBeat;