import React, {useContext, useEffect} from "react";
import AuthContext from "../../contexts/auth";
import {getLanguageLocally, setToken} from "../../utils/profile";
import {useHistory} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Trans} from "react-i18next";
import Backdrop from "@material-ui/core/Backdrop";
import useAxios from "axios-hooks";

export const GitHubCallback = (props) => {

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const code = params.get('code');
    const state = params.get('state');

    const history = useHistory();
    const auth = useContext(AuthContext);

    const [{data, loading, error}] = useAxios(
        {
            url: '/api/octocat/v1/oauth/callback',
            params: {
                code: code,
                state: state,
                lang: getLanguageLocally()
            }
        }
    )

    useEffect(() => {
        if (data && data.token) {
            setToken(data.token);
            history.push("/");
            auth.fetch();
        }
    }, [data, auth, history])

    useEffect(() => {
        if (error) history.push("/")
    }, [error, history])

    return loading ? <Backdrop open={true}>
            <CircularProgress color="inherit" />&nbsp;&nbsp;<Trans>Loading...</Trans>
        </Backdrop> : <div />
}