import React, {useContext, useState} from "react"
import {Switch, Route, Redirect} from "react-router-dom";
import PJDrawer from "../components/common/drawer";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core";
import FeedPage from "./feed";
import HeartBeat from "../components/feed/single";
import CheatsheetsList from "../components/cheatsheets/list";
import AuthContext from "../contexts/auth";
import CreateSoul from "./create_soul";
import LandingPage from "./landing/page";
import TagsContext, {getTagsContextValue} from "../contexts/tags";
import useAxios from "axios-hooks";
import {getAxiosHeaders} from "../api/client";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: {
    [theme.breakpoints.down('sm')]: theme.mixins.toolbar,
    [theme.breakpoints.up('sm')]: {
      minHeight: theme.spacing(3)
    }
  },
  content: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0
    }
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: 'none',
  },
}));

export const ProudJuniorPage = () => {
    const auth = useContext(AuthContext);

    return auth.user.soul ? <ProudJunior /> : <CreateSoul />
}

export const ProudJunior = () => {
  const classes = useStyles();

  const [tagsSize, setTagsSize] = useState(5);

  const [{data: tagsData, loading: tagsLoading}, doFetchTags] = useAxios({
    url: '/api/memory/v1/heartbeats/tags',
    headers: getAxiosHeaders(),
    params: {size: tagsSize}
  }, {useCache: false});

  const getTagsContextValue = () => {
      return {
          data: tagsData,
          size: tagsSize,
          setSize: setTagsSize,
          loading: tagsLoading,
          fetch: doFetchTags,
      }
  }

  return (
    <div className={classes.root}>
    <PJDrawer/>
    <main className={classes.content}>
      <div className={classes.appBarSpacer}/>
      <Container className={classes.container}>
          <TagsContext.Provider value={getTagsContextValue()}>
          <Switch>
              <Route path="/feed">
                <FeedPage />
              </Route>
                  <Route path="/cheatsheets" exact>
                    <CheatsheetsList />
                  </Route>
                  <Route path="/activity/:activityId" exact>
                    <HeartBeat />
                  </Route>
              <Route path="/" exact>
                <Redirect to="/feed" />
              </Route>
              <Route>
                  <Redirect to="/feed" />
              </Route>
          </Switch>
          </TagsContext.Provider>
      </Container>
    </main>
    </div>
  )
};

export default ProudJuniorPage;