import React, {useContext, useEffect, useState} from "react";
// import AuthContext from "../../contexts/auth";
import useScrollPosition from "@react-hook/window-scroll";
import {useTranslation} from "react-i18next";
import useAxios from "axios-hooks";
import {getAxiosHeaders} from "../../api/client";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import {CompactItemLine} from "./compact";
import {LinearProgress, makeStyles} from "@material-ui/core";
import AddActionMenu from "../feed/add";
import TagsContext from "../../contexts/tags";
const _ = require("lodash");


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    list: {
        width: '100%'
    },
    ...theme.types
}));


const HeartBeats = (props) => {

    const [heartbeats, setHeartbeats] = useState([]);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const {done, type, own, forceCard, tags, setTags, query, hideRating} = props;
    const classes = useStyles();
    // const auth = useContext(AuthContext);
    const tagsContext = useContext(TagsContext);
    const scrollY = useScrollPosition(10);
    const { t } = useTranslation();

    const [{data, loading}] = useAxios({
        url: '/api/memory/v1/heartbeats',
        headers: getAxiosHeaders(),
        params: {done, type, own, offset, tags, query},
    }, {useCache: false});

    const insertWithoutReload = (item) => {
        if ((type && item.type !== type) ||
            (!type && item.type === "cheatsheet") ||
            (own && !item.is_author) ||
            (done && !item.done_at)) {
            return
        }

        setHeartbeats([item].concat(...heartbeats));
        // auth.fetch();
        setTimeout(tagsContext.fetch, 1000)

    }

    const updateWithoutReload = (item) => {
        setHeartbeats(_.map(heartbeats, (heartbeat) => {
            return heartbeat.id === item.id ?
                _.assign({}, heartbeat, item) :
                heartbeat;
        }));
        // auth.fetch();
        setTimeout(tagsContext.fetch, 1000)
    }

    const removeWithoutReload = (item) => {
        setHeartbeats(_.filter(heartbeats, (heartbeat) => {
            return heartbeat.id !== item.id}));
        setTimeout(tagsContext.fetch, 1000)
    }

    const fetchNextPage = () => {
        setOffset(heartbeats ? heartbeats.length : 0)
    }

    useEffect(() => {
        setOffset(0);
        setHeartbeats([]); // eslint-disable-next-line
    }, [done, type, own, tags, query])

    useEffect(() => {
        if (data && data.items) {
            setHasMore(offset + data.items.length < data.count);
            setHeartbeats( (heartbeats) => heartbeats ? _.concat(heartbeats, data.items) : data.items);
        } // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (hasMore) {
            const bottom = Math.abs(window.innerHeight + scrollY - document.body.scrollHeight) < 20;
            if (bottom) {
                fetchNextPage();
            }
        } // eslint-disable-next-line
    }, [scrollY]);

    return <div className={classes.list}>
        <Grid container spacing={3}>
            {!loading && heartbeats.length === 0 ?
                <Grid item xs={12}>
                    <Box width="100%" display="flex" justifyContent="center" alignItems="center" p={5}>
                        <Typography variant="h4">
                            {t('activitiesList.empty')}&nbsp;
                        </Typography>
                        <SentimentSatisfiedIcon fontSize="large" />
                    </Box>
                </Grid>: null}
            {_.map(heartbeats, (item) =>
                <CompactItemLine
                    key={item.id}
                    item={item}
                    setTags={setTags}
                    forceCard={forceCard}
                    hideRating={hideRating}
                    insert={insertWithoutReload}
                    update={updateWithoutReload}
                    remove={removeWithoutReload}
                />
            )}
            <Grid item xs={12}>
                {loading ? <LinearProgress style={{width: '100%'}} /> : null }
            </Grid>
        </Grid>
        <AddActionMenu insert={insertWithoutReload} type={type}/>
    </div>
}

export default HeartBeats;