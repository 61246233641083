import './i18n';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {SnackbarProvider} from "notistack";
import {makeStyles, ThemeProvider} from "@material-ui/core/styles";
import {BrowserRouter} from "react-router-dom";
import {getTheme} from "./theme";
const axios = require("axios");
const _ = require("lodash");

axios.defaults.paramsSerializer = (params) => {
    const search = new URLSearchParams();
    _.forEach(_.omitBy(params, _.isNil), (value, key) => {
        if (_.isArray(value)) {
            value.map((val) => search.append(key, val))
        } else {
            search.append(key, value)
        }
    });
    return new URLSearchParams(search).toString();
}



const Root = () => {
    const theme = getTheme();
    const classes = makeStyles ( () => ({
        success: {
            backgroundColor: theme.palette.primary.main + " !important",
        }
    }))();

    return <BrowserRouter>
        <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                             classes={{variantSuccess: classes.success}}>
                <App />
            </SnackbarProvider>
        </ThemeProvider>
    </BrowserRouter>
}

ReactDOM.render(<Root />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
