import React, {useEffect, useState} from 'react';
import { Trans } from 'react-i18next';
import LandingPage from "./pages/landing/page";
import AuthContext from "./contexts/auth";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {CssBaseline} from "@material-ui/core";
import ProudJuniorPage from "./pages/junior";
import {getCurrentToken, setToken, storeLanguageLocally} from "./utils/profile";
import 'moment/locale/ru';
import i18n from "i18next";
import useAxios from "axios-hooks";
import {getAxiosHeaders} from "./api/client";
import {Route, Switch} from "react-router-dom";
import {GitHubCallback} from "./pages/landing/github_callback";
import {EmailConfirmCallback} from "./pages/landing/email_callback";
import HeartBeatsSharedFeed from "./pages/public/shared";

const _ = require('lodash');



const App = () => {
  return <Switch>
    <Route exact path="/github">
      <GitHubCallback />
    </Route>
    <Route exact path="/confirm">
      <EmailConfirmCallback />
    </Route>
    <Route path="/">
      <PJApp />
    </Route>
  </Switch>
}

const PJApp = () => {

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const [{data: userData, error}, doFetchUser] = useAxios({
    url: '/api/auth/v1/users/by-token',
    headers: getAxiosHeaders(),
    params: {
      soul: 1
    },
  }, {});

  const fetchUser = () => {
    if (_.isEmpty(getCurrentToken())) {
      setLoading(false);
      return null;
    }
    setLoading(true);
    return doFetchUser();
  }

  useEffect(() => {
    if (userData) {
      setUser(userData);
      const lang = userData.language || "en";
      storeLanguageLocally(lang);
      i18n.changeLanguage(lang);
      setLoading(false);
    }
  }, [userData]);

  useEffect(() => {
    if (error) setLoading(false);
  }, [error])

  const logoutUser = () => {
    setToken("");
    setUser(null);
  }

  const getAuthContextValue = () => {
    return {
      user: user,
      fetch: fetchUser,
      logout: logoutUser,
    }
  }
  if (loading) {
    return <Backdrop open={true}>
      <CircularProgress color="inherit" />&nbsp;&nbsp;<Trans>Loading...</Trans>
    </Backdrop>
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <Switch>
        <Route path="/shared/:sharedId" exact>
          <HeartBeatsSharedFeed />
        </Route>
        <Route>
          <AuthContext.Provider value={getAuthContextValue()}>
              {!loading && user ? <ProudJuniorPage /> : <LandingPage />}
          </AuthContext.Provider>
        </Route>
      </Switch>
    </React.Fragment>
  );
}

export default App;
