import React, {useEffect, useState} from "react";
import {
    Avatar, Chip,
    Collapse,
    fade,
    Hidden,
    InputAdornment,
    makeStyles,
    Paper,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import {Redirect, Route, Switch, useLocation, useParams} from "react-router-dom";
import {grey} from "@material-ui/core/colors";
import clsx from "clsx";
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import {AvatarGroup} from "@material-ui/lab";
import Box from "@material-ui/core/Box";
import {LightenDarkenColor} from "lighten-darken-color";
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from "@material-ui/icons/Close";
import useDebounce from "../../utils/debounce";
import useScrollPosition from "@react-hook/window-scroll";
import useAxios from "axios-hooks";
import {getAxiosHeaders} from "../../api/client";
import Typography from "@material-ui/core/Typography";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import {LinearProgress} from "@material-ui/core";
import {BEATS} from "../../components/feed/beats";
import {getLanguageLocally} from "../../utils/profile";
import Link from "@material-ui/core/Link";
import TagsCloud from "../../components/feed/tags";
import Container from "@material-ui/core/Container";
import {SharedCompactItemLine} from "./shared/line";
import TagsContext from "../../contexts/tags";
import Logo from "../../components/logo";

const _ = require("lodash");

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    activeFilter: {
        cursor: 'pointer',
        color: theme.palette.getContrastText(grey[900]),
        backgroundColor: grey[900],
        '&:hover': {
            color: theme.palette.getContrastText(grey[800]),
            backgroundColor: grey[800],
        }
    },
    typeFilter: {
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.getContrastText(grey[600]),
            backgroundColor: grey[600],
        }
    },
    list: {
        width: '100%'
    },
    filters: {
        display: 'flex',
        flexGrow: 1,
        paddingLeft: '8px !important'
    },
    ownFilter: {
        marginRight: theme.spacing(2),
    },
    ownFilterAvatar: {
        cursor: 'pointer',
        color: theme.palette.colorless.main,
        backgroundColor: 'transparent',
        '&:hover': {
            color: LightenDarkenColor(theme.palette.secondary.main, -15),
        }
    },
    ownFilterActiveAvatar: {
        cursor: 'pointer',
        color: theme.palette.secondary.main,
        backgroundColor: 'transparent',
        '&:hover': {
            color: LightenDarkenColor(theme.palette.secondary.main, -15),
        }
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
    pointer: {
        cursor: 'pointer',
    },
    content: {
        minHeight: theme.spacing(8),
        height: '100%',
        width: '100%',
    },
    wrapper: {
        width: '100%',
        maxWidth: '100%',
    },
    paper: {
        width: '100%',
        '&:hover': {
            backgroundColor: theme.palette.background.default
        },
    },
    small: {
        width: theme.spacing(3.5),
        height: theme.spacing(3.5),
    },
    type: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: theme.spacing(1),
    },
    itemData: {
        display: 'flex',
        width: '100%',
        height: '64px',
        // justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            height: 'auto'
        }
    },
    typeIcon: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        width: theme.spacing(4),
    },
    mobileInsight: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(1),
        width: theme.spacing(4),
        color: theme.palette.primary.light
    },
    image: {
        marginLeft: theme.spacing(1.5),
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '100%',
        minHeight: '64px',
        minWidth: '100px',
        maxWidth: '100px',
    },
    wideImage: {
        marginLeft: 0,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '100px',
        minHeight: '100px',
        minWidth: '100%',
        maxWidth: '100%',
    },
    markdown: {
        width: '100%',
        overflowX: 'auto',
    },
    text: {
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(1.5),
        width: 'calc(100% - 26px)',
        flexBasis: 'calc(100% - 26px)'
        // maxWidth: '100%',
    },
    mobileText: {
        paddingLeft: theme.spacing(0.5),
        // whiteSpace: 'normal'
    },
    imagedText: {
        width: 'calc(100% - 136px)',
        flexBasis: 'calc(100% - 136px)'
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down("sm")]: {
            whiteSpace: 'normal',
            ordBreak: 'break-all',
        }
    },
    date: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    noBorder: {
        border: '0 !important',
    },
    controls: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    tagLink: {
        marginRight: theme.spacing(2),
    },
    rootWrapper: {
        display: 'flex',
    },
    appBarSpacer: {
        [theme.breakpoints.up('sm')]: {
            minHeight: theme.spacing(3)
        }
    },
    contentWrapper: {
        width: '100%',
        paddingLeft: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0
        }
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        // maxWidth: ,
    },
    ...theme.types
}));


const HeartBeatsSharedFeed = () => {

    const classes = useStyles();
    const {t} = useTranslation();

    const [done, setDone] = useState('1');
    const [type, setType] = useState(null);
    const [query, setQuery] = useState("");
    const [ownContent, setOwnContent] = useState(null);
    const [tags, setTags] = useState([]);
    const [tagsSize, setTagsSize] = useState(5);

    const debouncedQuery = useDebounce(query);

    const [heartbeats, setHeartbeats] = useState([]);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const {sharedId} = useParams();
    const scrollY = useScrollPosition(10);

    const [{data, loading}] = useAxios({
        url: `/api/memory/v1/heartbeats/${sharedId}/shared`,
        headers: getAxiosHeaders(),
        params: {done, type, own: ownContent, offset, tags, query: debouncedQuery},
    });

    const [{data: tagsData, loading: tagsLoading}, doFetchTags] = useAxios({
        url: `/api/memory/v1/heartbeats/${sharedId}/shared-tags`,
        headers: getAxiosHeaders(),
        params: {size: tagsSize}
    });

    const fetchNextPage = () => {
        setOffset(heartbeats ? heartbeats.length : 0)
    }

    useEffect(() => {
        setOffset(0);
        setHeartbeats([]); // eslint-disable-next-line
    }, [done, type, ownContent, tags, debouncedQuery])

    useEffect(() => {
        if (data && data.items) {
            setHasMore(offset + data.items.length < data.count);
            setHeartbeats((heartbeats) => heartbeats ? _.concat(heartbeats, data.items) : data.items);
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (hasMore) {
            const bottom = Math.abs(window.innerHeight + scrollY - document.body.scrollHeight) < 20;
            if (bottom) {
                fetchNextPage();
            }
        } // eslint-disable-next-line
    }, [scrollY]);

    useEffect(() => {
        document.title = `ipsly: ${done ? "behind" : "roadmap"}`;
    }, [done])

    const toggleOwnFilter = (event) => {
        setOwnContent(ownContent ? null : true);
    }

    const toggleTypeFilter = (key) => {
        // This is for future, when we will support multiple types for filtering
        // if (types.indexOf(key) === -1) {
        //     setTypes(_.union(types, [key]));
        // } else {
        //     setTypes(_.filter(types, (el) => el !== key));
        // }
        setType(key === type ? null : key);
    }

    const getTagsContextValue = () => {
        return {
            data: tagsData,
            size: tagsSize,
            setSize: setTagsSize,
            loading: tagsLoading,
            fetch: doFetchTags,
        }
    }

    const handleAddTags = (tag) => {
        if (tags.indexOf(tag) === -1) {
            setTags(tags ? _.concat(tags, tag) : [tag]);
        }
    }

    const handleDeleteTags = (tag, index) => {
        setTags(_.filter(tags, (value) => value !== tag))
    }

    return <div className={classes.rootWrapper}>
        <main className={classes.contentWrapper}>
            <Container className={classes.container}>
                <Box display="flex" alignItems="center" className={classes.appBarSpacer}>
                    <Box pr={8} pb={2}>
                        <Link href="/"><Logo height="150px" width="150px" /></Link>
                    </Box>
                    <Typography variant="h4">ipsly.com</Typography>
                </Box>

                <Grid container spacing={4}>
                    <Grid item sm={6} xs={12}>
                        <Tabs
                            value={done}
                            variant="fullWidth"
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={(event, val) => setDone(val)}
                        >
                            <Tab
                                label={t('activitiesList.behind')}
                                value="1"/>
                            <Tab
                                label={t('activitiesList.roadmap')}
                                value="0"/>
                        </Tabs>
                    </Grid>

                    <Grid item md={3} xs={12} className={classes.filters}>
                        <AvatarGroup max={10} className={classes.ownFilter}>
                            <Tooltip title={t("activitiesList.own")} placement="top">
                                <Avatar
                                    onClick={toggleOwnFilter}
                                    alt={t("activitiesList.own")}
                                    className={clsx({
                                        [classes.ownFilterAvatar]: !ownContent,
                                        [classes.ownFilterActiveAvatar]: ownContent,
                                    })}
                                ><EmojiPeopleIcon fontSize="large"/></Avatar>
                            </Tooltip>
                        </AvatarGroup>

                        <AvatarGroup max={10}>
                            {_.map(_.values(BEATS), ({key, title, icon, enabled, filter}) =>
                                enabled && filter ? <Tooltip title={t(title)} placement="top" key={key}>
                                    <Avatar
                                        onClick={() => toggleTypeFilter(key)}
                                        alt={title}
                                        className={clsx(classes[`avatar-${key}-hover`], {
                                            [classes[`avatar-${key}`]]: type === key,
                                            [classes.typeFilter]: type !== key,
                                            [classes.activeFilter]: type === key
                                        })}
                                    >{icon()}</Avatar></Tooltip> : null
                            )}
                        </AvatarGroup>
                    </Grid>
                    <Grid item md={3} xs={12}>

                        <TextField
                            label={t("activitiesList.search")}
                            value={query}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon/>
                                    </InputAdornment>
                                ),
                                endAdornment: <InputAdornment position="end">
                                    {query ?
                                        <CloseIcon fontSize="small" onClick={() => setQuery("")}
                                                   className={classes.pointer}/> : " "
                                    }
                                </InputAdornment>,
                            }}
                            onChange={(event) => setQuery(event.target.value)}/>
                    </Grid>

                    <Grid item md={9} xs={12}>
                        {!_.isEmpty(tags) && <Box my={2}>{_.map(tags, (tag) => {
                            return <Box component="span" p={0.5} key={tag}>
                                <Chip label={tag} variant="outlined" onClick={() => handleDeleteTags(tag)}
                                      onDelete={() => handleDeleteTags(tag)}/>
                            </Box>
                        })}</Box>}

                        <div className={classes.list}>
                            <Grid container spacing={3}>
                                {!loading && heartbeats.length === 0 ?
                                    <Grid item xs={12}>
                                        <Box width="100%" display="flex" justifyContent="center" alignItems="center"
                                             p={5}>
                                            <Typography variant="h4">
                                                {t('activitiesList.empty')}&nbsp;
                                            </Typography>
                                            <SentimentSatisfiedIcon fontSize="large"/>
                                        </Box>
                                    </Grid> : null}
                                {_.map(heartbeats, (item, index) =>
                                    <SharedCompactItemLine
                                        key={index}
                                        item={item}
                                        setTags={setTags}
                                    />
                                )}
                                <Grid item xs={12}>
                                    {loading ? <LinearProgress style={{width: '100%'}}/> : null}
                                </Grid>
                            </Grid>
                        </div>

                    </Grid>

                    <Hidden mdDown>
                        <Grid item xs={3}>
                            <TagsContext.Provider value={getTagsContextValue()}>
                                <TagsCloud addTag={handleAddTags} deleteTag={handleDeleteTags} tags={tags}/>
                            </TagsContext.Provider>
                            <p>&nbsp;</p>
                        </Grid>
                    </Hidden>
                </Grid>
            </Container>
        </main>
    </div>
}


export default HeartBeatsSharedFeed;
