import React, {useContext, useEffect} from "react";
import {useHistory} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Trans, useTranslation} from "react-i18next";
import Backdrop from "@material-ui/core/Backdrop";
import useAxios from "axios-hooks";
import {useSnackbar} from "notistack";
import {getAxiosHeaders} from "../../api/client";

export const EmailConfirmCallback = (props) => {

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const code = params.get('code');

    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation();

    const [{loading, response, error}] = useAxios(
        {
            method: 'POST',
            headers: getAxiosHeaders(),
            url: '/api/auth/v1/users/confirm-email',
            data: {
                code: code,
            }
        }
    )

    useEffect(() => {
        if (response && response.status === 204) {
            enqueueSnackbar(
                t("landing.emailConfirmed"), {
                    variant: 'success'
                });
            history.push("/");
        }
    }, [response, history])

    useEffect(() => {
        if (error) {
            enqueueSnackbar(
                t("landing.emailNotConfirmed"), {
                    variant: 'error',
                });
            history.push("/")
        }
    }, [error, history])

    return loading ? <Backdrop open={true}>
            <CircularProgress color="inherit" />&nbsp;&nbsp;<Trans>Loading...</Trans>
        </Backdrop> : <div />
}