import {makeStyles} from "@material-ui/core";
import React from "react";


const useStyles = makeStyles((theme) => ({
    soul: {
        filter: "drop-shadow(0px 0px 1px rgba(0,0,0,1))",
        maxWidth: '350px',
        [theme.breakpoints.up('xs')]: {
            height: 'auto',
        },
    }
}));

export const Logo = ({height, width}) => {

    const classes = useStyles();
    return <img src="/logo512.png" height={height || "350px"} alt="ipsly.com" width={width || null} className={classes.soul}/>
}

export default Logo;
