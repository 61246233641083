import HeartBeatForm from "./base";


export const LinkedBeatForm = (props) => {
    return (<HeartBeatForm linkField={true} linkRequired={true} {...props}/>)
};

export const OptionalLinkedBeatForm = (props) => {
    return (<HeartBeatForm linkField={true} {...props}/>)
};

export const BookBeatForm = (props) => {
    return (<HeartBeatForm linkField={true} authorField={true} {...props}/>)
};

export const CheatsheetBeatForm = (props) => {
    return (<HeartBeatForm fixedType={true} type="cheatsheet" endpoint="cheatsheets" linkField={false} alwaysDone={true} alwaysAuthor={true}{...props}/>)
};
