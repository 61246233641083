import ProudAPI from "./client";
import {getLanguageLocally} from "../utils/profile";

const moment = require('moment-timezone');

export const getCurrentUser = () => {
  return ProudAPI.get('/api/auth/v1/users/by-token');
};

export const getCurrentSoul = () => {
  return ProudAPI.get('/api/soul/v1/souls/by-token');
};

export const setSoul = (avatar) => {
  const soul = {
    'avatar': avatar,
    'timezone': moment.tz.guess(),
    'modification': 'default'
  }
  return ProudAPI.post('/api/soul/v1/souls', soul);
};


export const getOAuthLink = () => {
  return ProudAPI.get('/api/octocat/v1/oauth/login');
};

export const checkOAuthCode = (code, state) => {
  return ProudAPI.get('/api/octocat/v1/oauth/callback',
      {code: code, state: state, lang: getLanguageLocally()});
};

// export const setAvatar = (data) => {
//   return ProudAPI.patch('/api/v1/me', data);
// };