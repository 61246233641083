import {useTranslation} from "react-i18next";
import { LinearProgress, TextField} from "@material-ui/core";
import React, {useEffect} from "react";
import useDebounce from "../../utils/debounce";
import useAxios from "axios-hooks";
import {getAxiosHeaders} from "../../api/client";
import {isValidHttpUrl} from "../../utils/validators";
import Box from "@material-ui/core/Box";
import {humanizeMinutes} from "../../utils/duration";

const _ = require("lodash");

const LinkField = (props) => {

  const {
    setTitle,
    setTimeSpent,
    setType,
    autoFocus,
    link,
    setLink,
    required,
    errors,
    setErrors
  } = props;

  // const [ogTags, setOgTags] = useState();

  const debouncedLink = useDebounce(link);
  const { t } = useTranslation();

  const handleChange = (event) => {
    const url = event.target.value;
    if (!url && required) {
      setErrors(_.extend({link: "required"}, errors));
    } else if (url && !isValidHttpUrl(url)) {
      setErrors(_.extend({link: "invalid"}, errors));
    } else {
      setErrors(_.omit(errors, ['link']))
    }
    setLink(url);
  }

  const [{data, loading}, fetch] = useAxios({
    url: '/api/snuffer/v1/og-tags',
    headers: getAxiosHeaders(),
  }, { manual: true })

  useEffect(
    () => {
      if (debouncedLink && isValidHttpUrl(debouncedLink)) {
        fetch({
          params: {
            url: debouncedLink
          }})
      } else {
        // setOgTags(null);
      }
    },
    [debouncedLink, fetch]
  );

  useEffect(() => {
    if (!_.isEmpty(data)) {
      // setOgTags(data);
      if (setTitle !== undefined && data.title) {
        setTitle((title) => !title ? data.title : title)
      }
      if (setTimeSpent !== undefined && data.duration) {
        setTimeSpent((duration) => !duration ? humanizeMinutes(data.duration) : duration)
      }
      if (setType !== undefined && data.type) {
        setType((type) => !type || type === "general" ? data.type : type)
      }
    }
  }, [data, setTitle, setTimeSpent]);

  return (
    <React.Fragment>
      <TextField
        id="link"
        variant="outlined"
        size="small"
        style={{width: "50%"}}
        autoFocus={autoFocus}
        label={t("activityForm.fields.link")}
        value={link}
        error={_.has(errors, 'link')}
        required={required}
        onChange={handleChange}
      />
      <Box position="relative" width="100%">
      {loading ? <LinearProgress style={{
        position:'absolute',
        width: '100%',
        height: '2px',
        top: '-2px'
      }} /> : null}
      </Box>
      {/*{ogTags ? <ItemLinkCard {...ogTags} /> : null}*/}
    </React.Fragment>
  )
}

export default LinkField;