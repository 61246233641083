import React from "react";

import HeartBeatsFeed from "../components/feed/list";
import {useRouteMatch, Redirect, Route, Switch} from "react-router-dom";

const FeedPage = () => {

    let {path} = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/:direction`} exact>
                <HeartBeatsFeed />
            </Route>
            <Route path={`${path}`} exact>
                <Redirect to={`${path}/behind`}/>
            </Route>
        </Switch>
    )
}


export default FeedPage;