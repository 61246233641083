import React, {useState} from 'react';
import {
    Fab,
    makeStyles,
} from "@material-ui/core";
import {useTranslation} from "react-i18next";

import {BEATS} from "./beats";
import ActivityForm from "./forms";
import {SpeedDial, SpeedDialAction, SpeedDialIcon} from "@material-ui/lab";
import AddIcon from '@material-ui/icons/Add';
import {CheatsheetBeatForm} from "../forms/types";
import clsx from "clsx";

const _ = require("lodash");


const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    menuItem: {
        paddingLeft: theme.spacing(1.2),
        '&:hover': {
            backgroundColor: theme.palette.background.default,
        },
    },
    popper: {
        zIndex: theme.zIndex.drawer + 2,
        paddingBottom: '10px',
    },
    popperBox: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid',
        borderColor: theme.palette.background.default,
        boxShadow: theme.shadows[1],
        ...theme.shape
    },
    fab: {
        position: 'fixed',
        zIndex: theme.zIndex.drawer + 1,
        right: theme.spacing(4),
        bottom: theme.spacing(8),
        left: 'auto',
        [theme.breakpoints.up('md')]: {
            left: drawerWidth/2-28,
            right: 'auto',
        },
    },
    cheatsheet: {

    },
    ...theme.types
}));


const AddActionMenu = ({insert, type}) => {

    const classes = useStyles();

    // const [anchorEl, setAnchorEl] = useState(null);
    const [activityForm, setActivityForm] = useState();

    const { t } = useTranslation();

    // const handleClick = (event) => {
    //     setAnchorEl(anchorEl ? null : event.currentTarget);
    // };
    //
    // const handleMouseOver = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    //
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    const openActivityForm = (type) => {
        setActivityForm(type)
    };

    const closeActivityForm = () => {
        setActivityForm(null)
    }

    return (
        <React.Fragment>

            <Fab
                 color="secondary"
                 aria-label="add"
                 className={clsx(classes.fab, {
                     [classes[`avatar-cheatsheet`]]: type === "cheatsheet"
                 })}
                 onClick={() => openActivityForm(type || "general")}
            >
                <AddIcon />
            </Fab>

            {!_.isNil(activityForm) && <ActivityForm
                insert={insert}
                initialType={activityForm}
                Form={activityForm === "cheatsheet" ? CheatsheetBeatForm : null}
                open={true}
                handleClose={closeActivityForm}/>}

        </React.Fragment>
    );
}


export default AddActionMenu;