import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import LandingLanguageSwitcher from "../../components/common/landing_language_switcher";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import GitHubIcon from "@material-ui/icons/GitHub";
import React, {useEffect, useState} from "react";
import {getOAuthLink} from "../../api/profile";
import {makeStyles, useMediaQuery, useTheme} from "@material-ui/core";
import {storeLanguageLocally} from "../../utils/profile";
import {useTranslation} from "react-i18next";
import {useParams, useHistory} from "react-router-dom";
import SignUpDialog from "../../components/profile/signup";
import SignInDialog from "../../components/profile/signin";
import Logo from "../../components/logo";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        textAlign: 'left',
        alignItems: 'center',
        height: '100vh',
        paddingTop: theme.spacing(15),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(1),
        }
    },
    desktop: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        }
    },
    mobile: {
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        }
    },
    what: {
        fontSize: theme.typography.h6.fontSize,
    },
    centered: {
        textAlign: 'center',
    },
    button: {
        width: '200px',
        marginTop: theme.spacing(3),
        marginRight: theme.spacing(2)
    },
    text: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        }
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        color: '#000',
        // necessary for content to be below app bar
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
        [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-start',
        },
        ...theme.mixins.toolbar,
    },
    transparentBar: {
        width: '100%',
        position: 'absolute',
        // backgroundColor: 'transparent',
        boxShadow: 'none',
        color: theme.palette.primary.dark
    },
}));


const Buttons = () => {
    const [showSignIn, setShowSignIn] = useState(false);
    const [showSignUp, setShowSignUp] = useState(false);
    const classes = useStyles();
    const {t} = useTranslation();

    const login = () => {
        getOAuthLink().then((data) => {
            if (!data.error) {
                window.location = data.link;
            }
        }).catch((error) => {
        })
    }

    return <React.Fragment>
        <Button onClick={() => setShowSignUp(true)} color="secondary" variant="contained" className={classes.button}>
            {t('landing.doSignup')}
        </Button>
        <Button onClick={() => setShowSignIn(true)} variant="contained" className={classes.button}>
            {t('landing.doSignin')}
        </Button>
        <Box>
            <Button  variant="outlined"  onClick={login} className={classes.button}>
                <GitHubIcon fontSize="large"/> &nbsp; {t('landing.github')}
            </Button>
            <SignUpDialog open={showSignUp} setOpen={setShowSignUp}/>
            <SignInDialog open={showSignIn} setOpen={setShowSignIn}/>
        </Box>
    </React.Fragment>
}


export const LandingPageComponent = (props) => {

    const classes = useStyles();

    const {lang} = useParams();
    const [userLang, setUserLang] = useState(lang);
    const {t, i18n} = useTranslation();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const history = useHistory();

    useEffect(() => {
        if (userLang) {
            history.push(`/${userLang}`);
            storeLanguageLocally(userLang);
            i18n.changeLanguage(userLang);
        }
    }, [userLang, history, i18n])

    return <React.Fragment>
        <AppBar className={classes.transparentBar}>
        <Toolbar className={classes.toolbar}>
            <Button variant="outlined" color="inherit" href="/blog/">{t('landing.blog')}</Button>
            <Box ml={2}>
                <LandingLanguageSwitcher lang={userLang} setLang={setUserLang}/>
            </Box>
        </Toolbar>
    </AppBar>

    <Container maxWidth="lg">
        <Grid container className={classes.root} spacing={isSmall ? 2 : 10}>
            <Grid item xs={12} sm={5} className={classes.centered}>

                <Box><Logo height={'300px'}/></Box>

                <Typography variant="h3" component="h1">ipsly</Typography>
                <Typography variant="subtitle1" component="h1">- See you growing -</Typography>

            </Grid>

            <Grid item xs={12} sm={7} className={classes.text}>
                <Box my={4} className={classes.mobile}>
                    <Buttons />
                </Box>
                <Typography variant="body1" className={classes.what}>
                    {t('landing.what').split('\n').map(
                        (item, key) => {
                            return <span key={key}>{item}<br/></span>
                        })}
                </Typography>
                <Box mt={3} className={classes.desktop}>
                    <Buttons />
                </Box>
            </Grid>
        </Grid>
    </Container>

    </React.Fragment>
}
