
const moment = require("moment");

export const humanizeMinutes = (seconds) => {
    const duration = moment.duration(parseInt(seconds)*60*1000);
    let result = "";
    let days = Math.floor(duration.asDays());
    let hours = Math.floor(duration.hours());
    let minutes = Math.floor(duration.minutes());

    if (days > 0) {
        result += `${days}d`
    }
    if (hours > 0) {
        result += `${hours}h`
    }
    if (minutes > 0) {
        result += `${minutes}m`
    }
    return result
}