import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {getLanguageLocally} from "../../utils/profile";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {
    ClickAwayListener,
    Collapse,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, Grow,
    makeStyles,
    Paper, Popper,
    Tooltip, useMediaQuery, useTheme
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {BEATS} from "../feed/beats";
import Typography from "@material-ui/core/Typography";
import useAxios from "axios-hooks";
import {getAxiosHeaders} from "../../api/client";
import {urlsForType} from "../../utils/url";
import ActivityForm from "../feed/forms";
import Button from "@material-ui/core/Button";
import {ItemCardControls} from "./card_controls";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import clsx from "clsx";
import {RenderedMarkdown, RenderedText} from "../markdown/render";
import Link from "@material-ui/core/Link";
import LinkIcon from "@material-ui/icons/Link";
import {CheatsheetBeatForm} from "../forms/types";
import {Rating} from "@material-ui/lab";
const moment = require("moment-timezone");
const _ = require("lodash");

const useStyles = makeStyles((theme) => ({
    content: {
        minHeight: theme.spacing(8),
        height: '100%',
        width: '100%',
    },
    wrapper: {
        width: '100%',
        maxWidth: '100%',
    },
    paper: {
        width: '100%',
        '&:hover': {
            backgroundColor: theme.palette.background.default
        },
    },
    small: {
        width: theme.spacing(3.5),
        height: theme.spacing(3.5),
    },
    type: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: theme.spacing(1),
    },
    itemData: {
        display: 'flex',
        width: '100%',
        height: '64px',
        // justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            height: 'auto'
        }
    },
    typeIcon: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        width: theme.spacing(4),
    },
    mobileInsight: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(1),
        width: theme.spacing(4),
        color: theme.palette.primary.light
    },
    image: {
        marginLeft: theme.spacing(1.5),
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '100%',
        minHeight: '64px',
        minWidth: '100px',
        maxWidth: '100px',
    },
    wideImage: {
        marginLeft: 0,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '100px',
        minHeight: '100px',
        minWidth: '100%',
        maxWidth: '100%',
    },
    markdown: {
        width: '100%',
        overflowX: 'auto',
    },
    text: {
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(1.5),
        width: 'calc(100% - 26px)',
        flexBasis: 'calc(100% - 26px)'
        // maxWidth: '100%',
    },
    mobileText: {
        paddingLeft: theme.spacing(0.5),
        // whiteSpace: 'normal'
    },
    imagedText: {
        width: 'calc(100% - 136px)',
        flexBasis: 'calc(100% - 136px)'
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down("sm")]: {
            whiteSpace: 'normal',
            ordBreak: 'break-all',
        }
    },
    pointer: {
        cursor: 'pointer',
    },
    date: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    noBorder: {
        border: '0 !important',
    },
    controls: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    tagLink: {
        marginRight: theme.spacing(2),
    },
    ...theme.types
}));

export const CompactItemLine = (props) => {

    const classes = useStyles();
    const {item, forceCard, insert, update, remove, setTags, hideRating} = props;
    const [card, setCard] = useState(forceCard);
    const [copied, setCopied] = useState(false);
    const copiedLeaveDelay = copied ? 1200 : 0;
    const activityLink = new URL(`/activity/${item.id}`, document.baseURI).href;

    const [form, setForm] = useState(false);
    const [confirmRemove, setConfirmRemove] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const {t} = useTranslation();
    // Required to re-render dates on language change
    moment.locale(getLanguageLocally());
    const copiedText = t(copied ? "itemLine.copied" : "itemLine.copy");

    const toggleCard = () => {
        // if (forceCard) return;
        setCard(!card);
    }
    const handleLinkCopy = (text, result) => {
        setCopied(true);
    }
    useEffect(() => {
        if (copied) {
            setTimeout(() => setCopied(false), 2000)
        }
    }, [copied])

    const dateOrPeriod = (date) => {
        const dateObj = moment(date);
        if (moment().diff(dateObj, 'seconds') > 24*60*60) {
            return dateObj.format('LLL')
        }
        return dateObj.fromNow();
    }

    const [{loading: removing, response: removeResponse}, doRemove] = useAxios({
        baseURL: '/api/heartbeat/v1',
        method: 'DELETE',
        headers: getAxiosHeaders(),
        validateStatus: (status) => status >= 200 && status < 500,
    }, {
        manual: true,
    });

    useEffect( () => {
        if (removeResponse && removeResponse.status === 204) {
            remove(item);
        } // eslint-disable-next-line
    }, [removeResponse]);

    const openForm = () => {
        setForm(true);
    }

    const closeForm = () => {
        setForm(false);
    }

    const removeActivity = () => {
        setConfirmRemove(true);
    };

    const handleConfirmRemove = () => {
        doRemove({
            url: `${urlsForType[item.type] || 'heartbeats'}/${item.id}`
        })
    }

    const handleCancelRemove = () => {
        setConfirmRemove(false);
    }

    const [anchorEl, setAnchorEl] = useState();

    const handleToolbarClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }
    const handleToolbarClose = (event) => {
        setAnchorEl(null);
    }

    return <Grid item xs={12}>

        <Paper className={classes.paper} variant="outlined">
            <Grid container alignItems="stretch" alignContent="center">
                <Grid item xs={12}>
                    <Box display="flex" className={classes.content} >
                        <Box className={clsx(classes.type, {[classes[`type-${item.type}`]]: true})}> </Box>
                        <Box width="calc(100% - 8px)">

                            {item.link_meta && item.link_meta.image && isMobile ?
                                <Link href={item.link} target="_blank" underline="none">
                                    <Box className={classes.wideImage}
                                         style={{backgroundImage: `url(${item.link_meta.image})`}}>
                                        &nbsp;
                                    </Box>
                                </Link> : null}
                                <Grid container>
                                    <Grid item xs={isMobile ? 11 : 10}>
                                        <Box className={classes.itemData}>
                                            <Box className={classes.typeIcon}>
                                                <Tooltip title={t(BEATS[item.type].title)} placement="top" >
                                                    {BEATS[item.type].icon()}
                                                </Tooltip>
                                            </Box>

                                            {item.link_meta && item.link_meta.image && !isMobile ?
                                                <Link href={item.link} target="_blank" underline="none">
                                                    <Box className={classes.image}
                                                         style={{backgroundImage: `url(${item.link_meta.image})`}}>
                                                        &nbsp;
                                                    </Box>
                                                </Link> : null}

                                            <Box className={clsx(classes.text, {
                                                [classes.mobileText]: isMobile,
                                                [classes.imagedText]: !isMobile && item.link_meta && item.link_meta.image
                                            })} onClick={toggleCard} >

                                                <Typography
                                                    variant={isMobile ? "body2" : "body2" }
                                                    className={classes.title}>
                                                    {item.title}
                                                </Typography>

                                                <Box className={classes.tags} display="flex" alignItems="center">
                                                    {item.done_at && !hideRating && <Rating name="read-only" size="small"
                                                             value={item.rating} readOnly
                                                             style={{marginRight: "16px", marginLeft: "-3px"}}/>
                                                    }
                                                    {item.tags ? _.map(item.tags, (tag) => {
                                                        // return <Chip  key={tag} variant="outlined" label={`#${tag}`} onClick={() => setTags(tag)} className={classes.tagLink}/>
                                                        return <Link key={tag} component="button" onClick={() => setTags(tag)} className={classes.tagLink}>{tag}</Link>
                                                    }) : null}
                                                </Box>

                                            </Box>
                                    </Box>

                                </Grid>
                                <Grid item xs={isMobile ? 1 : 2} className={classes.controls}>

                                    { !isMobile ?
                                        <ItemCardControls edit={openForm} remove={removeActivity} insight={toggleCard} item={item}/>
                                        : null }
                                    { isMobile ? <ClickAwayListener onClickAway={handleToolbarClose}>
                                        <div>
                                            <IconButton
                                                aria-label="more"
                                                aria-controls="long-menu"
                                                aria-haspopup="true"
                                                // className={classes.menuButton}
                                                onClick={handleToolbarClick}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>

                                            <Popper
                                                open={!!anchorEl}
                                                anchorEl={anchorEl}
                                                className={classes.popper}
                                                role={undefined}

                                                placement="left"
                                                transition
                                            >
                                                {({ TransitionProps, placement }) => (
                                                    <Grow
                                                        {...TransitionProps}
                                                        style={{ transformOrigin: placement === 'bottom' ? 'center top ' : 'center bottom' }}
                                                    >
                                                        <Paper className={classes.popperBox}>
                                                            <ItemCardControls edit={openForm} remove={removeActivity} insight={toggleCard}
                                                                              item={item} close={handleToolbarClose}/>
                                                        </Paper>
                                                    </Grow>
                                                )}
                                            </Popper>
                                        </div>
                                    </ClickAwayListener>
                                    : null}
                                </Grid>
                            </Grid>
                            <Box>
                                {form ?
                                    <ActivityForm
                                        disabled={removing}
                                        insert={insert}
                                        update={update}
                                        initialType={item.type}
                                        Form={item.type === "cheatsheet" ? CheatsheetBeatForm: null}
                                        item={item}
                                        open={form}
                                        handleClose={closeForm}/> : null}
                                    <Collapse in={card} style={{ transformOrigin: '0 0 0' }}>
                                        <Box>
                                            {item.insight && <Box className={classes.markdown} p={2}>
                                                    {
                                                        item.format === "text" ?
                                                            // nl2br(item.insight)
                                                            <RenderedText text={item.insight}/>
                                                            : <RenderedMarkdown text={item.insight}/>
                                                    }
                                                </Box>}

                                            <Box className={classes.date} paddingBottom={1} paddingRight={1}>
                                                <Box pr={1}>
                                                    <Typography align="left" variant="caption">
                                                        {dateOrPeriod(item.done_at ? item.done_at : item.created_at)}
                                                    </Typography>
                                                </Box>
                                                <CopyToClipboard text={activityLink} onCopy={handleLinkCopy}>
                                                    <Tooltip title={copiedText} placement="right" leaveDelay={copiedLeaveDelay}>
                                                        <LinkIcon fontSize="small" color="primary" className={classes.pointer}/>
                                                    </Tooltip>
                                                </CopyToClipboard>
                                            </Box>
                                        </Box>
                                    </Collapse>

                                <Dialog
                                    disableBackdropClick
                                    disableEscapeKeyDown
                                    maxWidth="xs"
                                    aria-labelledby={t('itemCard.removeDialog.title')}
                                    open={confirmRemove}
                                >
                                    <DialogTitle>{t('itemCard.removeDialog.title')}</DialogTitle>
                                    <DialogContent dividers>
                                        {t('itemCard.removeDialog.message')} {t(`itemCard.removeDialog.${item.type}`)} <br/> <strong>{item.title}</strong>?
                                    </DialogContent>
                                    <DialogActions>
                                        <Button autoFocus onClick={handleCancelRemove} color="primary">
                                            {t('itemCard.removeDialog.cancel')}
                                        </Button>
                                        <Button onClick={handleConfirmRemove} color="secondary">
                                            {t('itemCard.removeDialog.remove')}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Box>
                        </Box>

                    </Box>
                </Grid>
            </Grid>

        </Paper>

    </Grid>

}