import Grid from "@material-ui/core/Grid";
import {makeStyles, Switch} from "@material-ui/core";
import {useContext, useEffect, useRef, useState} from "react";
import i18n from "i18next";
import {storeLanguageLocally} from "../../utils/profile";
import useAxios from "axios-hooks";
import {getAxiosHeaders} from "../../api/client";
import AuthContext from "../../contexts/auth";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}));

const LanguageSwitcher = (props) => {

    const {en, ru} = props;
    const isFirstRun = useRef(true);
    const auth = useContext(AuthContext);
    const [lang, setLang] = useState(i18n.language);
    const classes = useStyles();

    const handleChange = (event) => {
        setLang(event.target.checked ? "ru" : "en");
    }

    const [{response: saveResponse}, saveLanguage] = useAxios({
        baseURL: `/api/auth/v1/users/${auth.user.id}`,
        method: 'PATCH',
        headers: getAxiosHeaders(),
        validateStatus: (status) => status >= 200 && status < 500,
    }, {
        manual: true,
    });

    useEffect( () => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        saveLanguage({data: {language: lang}});
    }, [lang, saveLanguage]);

    useEffect(() => {
        if (saveResponse && saveResponse.status === 200) {
            storeLanguageLocally(lang);
            i18n.changeLanguage(lang);
        }
    }, [saveResponse, lang]);



    return <Grid component="label" container spacing={1} className={classes.root}>
            <Grid item>{en || "EN"}</Grid>
            <Grid item>
                <Switch checked={lang === "ru"} onChange={handleChange}  />
            </Grid>
            <Grid item>{ru || "RU"}</Grid>
        </Grid>
}

export default LanguageSwitcher;