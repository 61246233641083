import {
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    InputLabel,
    OutlinedInput,
    TextField, Tooltip,
} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';

import {useTranslation} from 'react-i18next';
import LinkField from "../link/link_field";
import React, {useEffect, useRef, useState} from "react";

import MarkdownField from "../markdown/markdown_field";
import Button from "@material-ui/core/Button";
import useAxios from "axios-hooks";
import {getAxiosHeaders} from "../../api/client";
import {useSnackbar} from "notistack";
import {Autocomplete, Rating, ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import MomentUtils from '@date-io/moment';

import {MuiPickersUtilsProvider, DatePicker} from "@material-ui/pickers";
import SaveIcon from '@material-ui/icons/Save';
import Box from "@material-ui/core/Box";
import {BEATS} from "../feed/beats";
import clsx from "clsx";
import {TYPES_COLORS} from "../../theme";
import {humanizeMinutes} from "../../utils/duration";
import parse from "parse-duration"
import Typography from "@material-ui/core/Typography";
import {Label} from "@material-ui/icons";

const moment = require("moment-timezone");
const _ = require("lodash");

const DURATION_REGEX = /^((\d+.)?\d+(d|д))?((\d+.)?\d+(h|ч))?((\d+.)?\d+(m|м))?$/

const useStyles = makeStyles((theme) => {

    const styles = {
        doneState: {
            width: '100%',
        },
        markdownField: {
            '& div': {
                height: '100%',
            }
        },
        helperText: {
            color: theme.palette.grey[900],
        },
        button: {
            color: theme.palette.common.white
        },
        typeToggleButton: {
            borderTop: 0,
            "&:last-child": {
                borderTopRightRadius: 0,
            },
            "&:first-child": {
                borderTopLeftRadius: 0,
            }
        },
        typeTab: {
            padding: theme.spacing(0.5),
            alignItems: "center",
            textAlign: "center",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            marginRight: "1px",
            backgroundColor: theme.palette.background.paper,
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderBottom: 0,
            height: "30px",
            cursor: "pointer",
            "&:last-child": {
                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                marginRight: "0px",
            },
            "&:first-child": {
                borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
            }
        },
    }

    _.forEach(_.keys(TYPES_COLORS), (t) => {
        styles[`type-${t}-hover`] = {
            "&:hover": {
                backgroundColor: TYPES_COLORS[t],
                color: '#fff',
            }
        }
        styles[`type-${t}-active`] = {
            backgroundColor: TYPES_COLORS[t],
            color: '#fff',
        }
    });

    return styles;
});

const HeartBeatForm = (props) => {

    const {
        item,
        linkRequired,
        alwaysDone,
        fixedType,
        linkField,
        type,
        setType,
        endpoint,
        alwaysAuthor,
        handleClose,
        update,
        insert,
    } = props;

    const classes = useStyles();

    const getValue = (obj, key, default_) => {
        return obj ? obj[key] ? obj[key] : default_ : default_
    }

    const [done, setDone] = useState(getValue(item, 'done_at') || !!alwaysDone);
    const [title, setTitle] = useState(getValue(item, 'title', ''));
    const doneAtDefault = alwaysDone ? moment.tz(moment(), moment.tz.guess()).format() : '';
    let doneAtValue = getValue(item, 'done_at');
    if (doneAtValue) {
        doneAtValue = moment.tz(moment(doneAtValue), moment.tz.guess()).format();
    }
    const [doneAt, setDoneAt] = useState(doneAtValue || doneAtDefault);

    let timeSpentValue = humanizeMinutes(getValue(item, 'time_spent', 0));

    const [timeSpent, setTimeSpent] = useState(timeSpentValue);
    const [link, setLink] = useState(getValue(item, 'link', ''));
    const [insight, setInsight] = useState(getValue(item, 'insight', ''));
    const [isAuthor, setIsAuthor] = useState(getValue(item, 'is_author', !!alwaysAuthor));
    const [tags, setTags] = useState(getValue(item, 'tags', []));
    const [rating, setRating] = useState(getValue(item, 'rating', null));
    const [tagsValue, setTagsValue] = useState("");
    const [errors, setErrors] = useState({});

    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation();

    const [{data: result, loading: saving, response}, doSave] = useAxios({
        baseURL: `/api/heartbeat/v1/${endpoint || 'heartbeats'}`,
        headers: getAxiosHeaders(),
        validateStatus: (status) => status >= 200 && status < 500,
    }, {
        manual: true,
    })

    const [{data: tagsOptions, loading: tagsLoading}] = useAxios({
        baseURL: `/api/memory/v1/heartbeats/tags`,
        headers: getAxiosHeaders(),
        validateStatus: (status) => status >= 200 && status < 500,
    }, {useCache: false})


    useEffect(() => {
        if (response && response.status < 300) {
            enqueueSnackbar(
                t("activityForm.snack.saved"), {
                    variant: 'success'
                });
            if (item) {
                update(_.assign({}, item, getUpdateItemData()));
            } else {
                insert(_.assign(result, {type: type}, getUpdateItemData()));
            }

            handleClose();
        } // eslint-disable-next-line
    }, [result, response, enqueueSnackbar, handleClose, update, insert]);

    const handleChangeDone = (event) => {
        let doneAtValue;
        setDone(event.target.checked);
        if (!event.target.checked) {
            doneAtValue = '';
        } else {
            doneAtValue = moment.tz(moment(), moment.tz.guess()).format();
        }
        setDoneAt(doneAtValue);
    }

    const handleChangeDoneAt = (value) => {
        setDoneAt(value);
    }

    useEffect(() => {
        if (!title) {
            setErrors((errorsState) => _.extend({title: "required"}, errorsState));
        } else {
            setErrors((errorsState) => _.omit(errorsState, ['title']))
        }
    }, [title])

    useEffect(() => {
        if (!DURATION_REGEX.test(timeSpent)) {
            setErrors((errorsState) => _.extend({timeSpent: "Wrong format"}, errorsState));
        } else {
            setErrors((errorsState) => _.omit(errorsState, ['timeSpent']))
        }
    }, [timeSpent])

    const handleChangeTitle = (event) => {
        setTitle(event.target.value);
    }
    const handleChangeTimeSpent = (event) => {
        setTimeSpent(event.target.value);
    }

    const changedKeys = (oldObj, newObj, keys) => {
        let result = {};
        _.forEach(keys, (key) => {
            if (oldObj[key] !== newObj[key]) {
                result[key] = newObj[key];
            }
        });
        return result;
    }

    const handleChangeIsAuthor = (event) => {
        setIsAuthor(event.target.checked);
    }

    const handleInsightChange = (event) => {
        setInsight(event.target.value);
    }

    const getUpdateItemData = () => {
        return {
            title: title,
            link: link,
            type: type,
            rating: rating,
            done_at: doneAt ? doneAt : null,
            time_spent: Math.ceil(parse(timeSpent.replace("д", "d").replace("ч", "h").replace("м", "m"), 'min')),
            is_author: isAuthor ? isAuthor : false,
            insight: insight,
            format: "markdown",
            tags: tags,
        };
    }

    const handleSaveClick = () => {
        let method = 'POST';
        let url = '';
        let heartbeat = getUpdateItemData();
        if (item) {
            method = 'PATCH';
            heartbeat = changedKeys(item, heartbeat, _.keys(heartbeat));
            url = `/${item.id}`;
        }
        if (_.isEmpty(errors)) {
            doSave({
                url: url,
                method: method,
                data: heartbeat,
            });
        }
    }

    const keydownHandler = (event) => {
        if (event.keyCode === 13 && (event.ctrlKey || event.metaKey)) {
            document.getElementById("submit").click();
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', keydownHandler);
        return () => document.removeEventListener('keydown', keydownHandler);
    }, []);

    return (<React.Fragment>

        {!fixedType && <Box display="flex" flexDirection="column" alignItems="center" width="100%">
            <ToggleButtonGroup
                value={type}
                exclusive
                onChange={(_, val) => setType(val || "general")}
            >
                {_.map(_.values(BEATS), ({key, title, icon, enabled, filter}) =>
                    enabled && filter && <Tooltip title={t(title)} key={key} placement="top">
                        <ToggleButton value={key} className={clsx(classes.typeToggleButton, {
                            [classes[`type-${key}-hover`]]: key,
                            [classes[`type-${key}-active`]]: key === type,
                        })}>
                            {icon({fontSize: "small"})}
                        </ToggleButton></Tooltip>
                )}
            </ToggleButtonGroup>
        </Box>}

        <Box display="flex" p={2} pt={fixedType ? 6 : 3} flexDirection="column" alignItems="center"
             height={fixedType ? "100%" : "calc(100% - 44px)"}
             style={{overflowY: 'auto',}}>
            <Box width="100%" pb={2}>
                <TextField
                    variant="outlined"
                    required
                    fullWidth={!linkField}
                    style={linkField ? {width: "50%", paddingRight: '8px'} : {}}
                    size="small"
                    error={_.has(errors, 'title')}
                    autoFocus={!linkRequired}
                    label={t("activityForm.fields.title")}
                    value={title}
                    onChange={handleChangeTitle}
                />
                {linkField && <LinkField
                    item={item}
                    title={title}
                    setTitle={setTitle}
                    timeSpent={timeSpent}
                    setTimeSpent={setTimeSpent}
                    type={type}
                    setType={setType}
                    errors={errors}
                    setErrors={setErrors}
                    autoFocus={linkRequired}
                    link={link}
                    setLink={setLink}
                    required={linkRequired}
                />}
            </Box>

            <Box width="100%" pb={2}>
                <Autocomplete
                    multiple
                    freeSolo
                    size="small"
                    options={tagsOptions ? _.keys(tagsOptions.tags) : []}
                    // options={_.mapValues(_.invert(_.invert(tagsOptions.tags)), parseInt)}
                    getOptionSelected={(option, value) => option.name === value}
                    getOptionLabel={(option) => option}
                    loading={tagsLoading}
                    value={tags}
                    inputValue={tagsValue}
                    onBlur={(event) => {
                        const options = tagsValue.split(/[\s,]+/);

                        if (options.length > 0) {
                            setTags(
                                tags
                                    .concat(options)
                                    .map(x => x.trim())
                                    .filter(x => x)
                            );
                        }
                    }}
                    onChange={(_, value) => setTags(value)}
                    onInputChange={(event, newInputValue) => {
                        const options = newInputValue.split(/[\s,]+/);

                        if (options.length > 1) {
                            setTags(
                                tags
                                    .concat(options)
                                    .map(x => x.trim())
                                    .filter(x => x)
                            );
                        } else {
                            setTagsValue(newInputValue);
                        }
                    }}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip size="small" label={option} {...getTagProps({index})}/>
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            variant="outlined"
                            error={_.has(errors, 'tags')}
                            label={t("activityForm.fields.tags")}
                        />
                    )}
                />
            </Box>

            <Box width="100%" flexGrow={1} flexShrink={0} pb={2} className={classes.markdownField}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="insightInput" shrink={true}>{t("activityForm.fields.insight")}</InputLabel>
                    <OutlinedInput
                        id="insightInput"
                        label={t("activityForm.fields.insight")}
                        placeholder={t("activityForm.fields.insightHelper")}
                        notched
                        inputProps={{
                            text: insight,
                            onChange: handleInsightChange,
                            label: t("activityForm.fields.insight"),
                            error: _.has(errors, 'insight')
                        }}
                        inputComponent={MarkdownField}
                    />
                    {/*<Typography className={classes.helperText}>{t("activityForm.fields.insightHelper")}</Typography>*/}
                </FormControl>
            </Box>

            {alwaysDone ? null : <React.Fragment>
                <Box pb={2} width="100%" display="flex">
                    {item && item.done_at ? null :
                        <FormControlLabel
                            value="start"
                            control={<Checkbox
                                checked={done}
                                onChange={handleChangeDone}
                                name="done"
                            />}
                            label={t("activityForm.fields.isDone")}
                            style={{width: "50%"}}
                            labelPlacement="end"
                        />}
                    {done && <Box width="50%" pl={1}>
                        <InputLabel htmlFor="insightInput" shrink={true}>{t("activityForm.fields.rating")}</InputLabel>
                        <Rating
                            value={rating}
                            onChange={(event, newValue) => {
                                setRating(newValue);
                            }}
                        />
                    </Box>}

                </Box>
                { done && <Box pb={2} width="100%" display="flex">

                    <MuiPickersUtilsProvider utils={MomentUtils} >
                        <DatePicker
                            disableToolbar
                            disableFuture
                            style={{width: "50%", paddingRight: '8px'}}
                            autoOk
                            format="LL"
                            disabled={!done}
                            variant="inline"
                            inputVariant="outlined"
                            size="small"
                            label={item && item.done_at ? t("activityForm.fields.doneAt") : null}
                            error={_.has(errors, /**/'done_at')}
                            // InputLabelProps={{ shrink: true }}
                            value={doneAt || null}
                            onChange={handleChangeDoneAt}
                        />

                        <TextField
                            variant="outlined"
                            size="small"
                            style={{width: "50%"}}
                            InputLabelProps={{ shrink: true }}
                            error={_.has(errors, 'timeSpent')}
                            label={t("activityForm.fields.timeSpent")}
                            placeholder={t("activityForm.fields.timeSpentHelper")}
                            value={timeSpent}
                            onChange={handleChangeTimeSpent}
                        />

                    </MuiPickersUtilsProvider>

                </Box>}
            </React.Fragment>}

            {!alwaysAuthor &&
            <Box pb={2} width="100%">
                <FormControlLabel
                    control={<Checkbox
                        checked={isAuthor}
                        onChange={handleChangeIsAuthor}
                    />}
                    label={t("activityForm.fields.isAuthor")}
                    labelPlacement="end"
                />
            </Box>}

            <Box width="100%" pb={2}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={saving}
                    fullWidth
                    className={classes.button}
                    startIcon={<SaveIcon/>}
                    onClick={handleSaveClick}
                    id="submit"
                >
                    {t("activityForm.save")}
                </Button>
            </Box>
        </Box>
    </React.Fragment>)
}

export default HeartBeatForm;