import {Card, CardContent, CardHeader} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";


const CompetenciesCloud = () => {

    const { t } = useTranslation();

    return <Card variant="outlined">
        <CardHeader
            title={t("competenciesCloud.title")}
            subheader={t("competenciesCloud.upcoming")}
        />
        <CardContent>
            {t("competenciesCloud.caption")}
            {/*<Box display="flex" flexWrap="wrap"></Box>*/}
        </CardContent>
    </Card>
}

export default CompetenciesCloud;