import React, { useEffect } from 'react';
import {Switch, Route, Redirect} from "react-router-dom";
import {GitHubCallback} from "./github_callback";
import {LandingPageComponent} from "./component";
import HeartBeatsSharedFeed from "../public/shared";


export const LandingPage = () => {

    useEffect(() => {
        document.title = 'ipsly.com';
    })

    let lang = "en";

    return (
        <Switch>
            <Route exact path="/github">
                <GitHubCallback />
            </Route>
            <Route path="/" exact>
                <Redirect to={`/${lang}`} />
            </Route>
            <Route path="/:lang([a-z]{2})" exact>
                <LandingPageComponent />
            </Route>
            <Redirect to='/' />
        </Switch>
    )
}

export default LandingPage;
