import React, {useContext} from 'react';
import AuthContext from "../contexts/auth";
import {SoulSwitchWidget} from "../components/profile/soul";
import Box from "@material-ui/core/Box";
import {makeStyles, useMediaQuery, useTheme} from "@material-ui/core";
import Container from "@material-ui/core/Container";


const useStyles = makeStyles(() => ({
    root: {

        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        height: '100vh',
    }
}));

const conditionalWrap = (cond, component) =>
    cond ? <Box m={2} pt={3}>{component}</Box> : component

const CreateSoul = () => {

    const auth = useContext(AuthContext);
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return <Container maxWidth="sm" className={classes.root}>
        {conditionalWrap(
            !isMobile,
            <SoulSwitchWidget current={auth.user.soul ? auth.user.soul.avatar : null} onUpdate={auth.fetch}/>)}
    </Container>
}


export default CreateSoul;