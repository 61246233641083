import React, {useState} from "react";
import {Collapse, makeStyles, Paper, Tooltip, useMediaQuery, useTheme} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {getLanguageLocally} from "../../../utils/profile";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import Link from "@material-ui/core/Link";
import {BEATS} from "../../../components/feed/beats";
import Typography from "@material-ui/core/Typography";
import {RenderedMarkdown, RenderedText} from "../../../components/markdown/render";

const moment = require("moment-timezone");
const _ = require("lodash");


const useStyles = makeStyles((theme) => ({
    content: {
        minHeight: theme.spacing(8),
        height: '100%',
        width: '100%',
    },
    wrapper: {
        width: '100%',
        maxWidth: '100%',
    },
    paper: {
        width: '100%',
        '&:hover': {
            backgroundColor: theme.palette.background.default
        },
    },
    small: {
        width: theme.spacing(3.5),
        height: theme.spacing(3.5),
    },
    type: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: theme.spacing(1),
    },
    itemData: {
        display: 'flex',
        width: '100%',
        height: '64px',
        // justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            height: 'auto'
        }
    },
    typeIcon: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        width: theme.spacing(4),
    },
    mobileInsight: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(1),
        width: theme.spacing(4),
        color: theme.palette.primary.light
    },
    image: {
        marginLeft: theme.spacing(1.5),
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '100%',
        minHeight: '64px',
        minWidth: '100px',
        maxWidth: '100px',
    },
    wideImage: {
        marginLeft: 0,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '100px',
        minHeight: '100px',
        minWidth: '100%',
        maxWidth: '100%',
    },
    markdown: {
        width: '100%',
        overflowX: 'auto',
    },
    text: {
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(1.5),
        width: 'calc(100% - 26px)',
        flexBasis: 'calc(100% - 26px)'
        // maxWidth: '100%',
    },
    mobileText: {
        paddingLeft: theme.spacing(0.5),
        // whiteSpace: 'normal'
    },
    imagedText: {
        width: 'calc(100% - 136px)',
        flexBasis: 'calc(100% - 136px)'
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down("sm")]: {
            whiteSpace: 'normal',
            ordBreak: 'break-all',
        }
    },
    pointer: {
        cursor: 'pointer',
    },
    date: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    noBorder: {
        border: '0 !important',
    },
    controls: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    tagLink: {
        marginRight: theme.spacing(2),
    },
    ...theme.types
}));

export const SharedCompactItemLine = (props) => {

    const classes = useStyles();
    const {item, setTags} = props;
    const [card, setCard] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const {t} = useTranslation();
    // Required to re-render dates on language change
    moment.locale(getLanguageLocally());

    const toggleCard = () => {
        setCard(!card);
    }

    const dateOrPeriod = (date) => {
        const dateObj = moment(date);
        if (moment().diff(dateObj, 'seconds') > 24*60*60) {
            return dateObj.format('LLL')
        }
        return dateObj.fromNow();
    }

    return <Grid item xs={12}>

        <Paper className={classes.paper} variant="outlined">
            <Grid container alignItems="stretch" alignContent="center">
                <Grid item xs={12}>
                    <Box display="flex" className={classes.content} >
                        <Box className={clsx(classes.type, {[classes[`type-${item.type}`]]: true})}> </Box>
                        <Box width="calc(100% - 8px)">

                            {item.link_meta && item.link_meta.image && isMobile ?
                                <Link href={item.link} target="_blank" underline="none">
                                    <Box className={classes.wideImage}
                                         style={{backgroundImage: `url(${item.link_meta.image})`}}>
                                        &nbsp;
                                    </Box>
                                </Link> : null}
                            <Grid container>
                                <Grid item xs={isMobile ? 11 : 10}>
                                    <Box className={classes.itemData}>
                                        <Box className={classes.typeIcon}>
                                            <Tooltip title={t(BEATS[item.type].title)} placement="top" >
                                                {BEATS[item.type].icon()}
                                            </Tooltip>
                                        </Box>

                                        {item.link_meta && item.link_meta.image && !isMobile ?
                                            <Link href={item.link} target="_blank" underline="none">
                                                <Box className={classes.image}
                                                     style={{backgroundImage: `url(${item.link_meta.image})`}}>
                                                    &nbsp;
                                                </Box>
                                            </Link> : null}

                                        <Box className={clsx(classes.text, {
                                            [classes.mobileText]: isMobile,
                                            [classes.imagedText]: !isMobile && item.link_meta && item.link_meta.image
                                        })} onClick={toggleCard} >

                                            <Typography
                                                variant={isMobile ? "body2" : "body2" }
                                                className={classes.title}>
                                                {item.title}
                                            </Typography>

                                            {item.tags ? <Box className={classes.tags}>
                                                {_.map(item.tags, (tag) => {
                                                    // return <Chip  key={tag} variant="outlined" label={`#${tag}`} onClick={() => setTags(tag)} className={classes.tagLink}/>
                                                    return <Link key={tag} component="button" onClick={() => setTags(tag)} className={classes.tagLink}>{tag}</Link>
                                                })}
                                            </Box> : null}

                                        </Box>
                                    </Box>

                                </Grid>

                            </Grid>
                            <Box>

                                <Collapse in={card} style={{ transformOrigin: '0 0 0' }}>
                                    <Box>
                                        {item.insight && <Box className={classes.markdown} p={2}>
                                            {
                                                item.format === "text" ?
                                                    <RenderedText text={item.insight}/>
                                                    : <RenderedMarkdown text={item.insight}/>
                                            }
                                        </Box>}

                                        <Box className={classes.date} paddingBottom={1} paddingRight={1}>
                                            <Box pr={1}>
                                                <Typography align="left" variant="caption">
                                                    {dateOrPeriod(item.done_at ? item.done_at : item.created_at)}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Collapse>
                            </Box>
                        </Box>

                    </Box>
                </Grid>
            </Grid>

        </Paper>

    </Grid>

}