import {createMuiTheme} from "@material-ui/core";
import { LightenDarkenColor } from 'lighten-darken-color';
const _ = require("lodash");

export const TYPES_COLORS = {
    general: '#31b3c0',
    article: '#87d494',
    video: '#eb6372',
    podcast: '#392F5A',
    book: '#735642',
    talk: '#C879FF',
    course: '#204f9d',
    cheatsheet: '#52a75d',

    chapter: 'rgb(144, 144, 144)',
    conference: 'rgb(144, 144, 144)',
    lesson: 'rgb(144, 144, 144)',
}

export const getTheme = () => {
    let overwrites = {
        typography: {
            "fontFamily": " \"Comfortaa\", \"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
        },
        palette: {
            // type: (t) => TYPES_COLORS[t] || 'rgb(144, 144, 144)',
            // types: TYPES_COLORS,
            primary: {
                main: '#31B3C0',
            },
            secondary: {
                main: "#FEBF2A",
            },
            colorless: {
                main: "#757575",
                light: "#bdbdbd",
                dark: "#4a4a4a",
            },
            background: {
                // default: 'rgba(255, 248, 225, 0.53)'
                // paper: grey[100],
            }
        },
        types: {},
    };
    _.forEach(_.keys(TYPES_COLORS), (t) => {
        overwrites.types[`type-${t}`] = {
            backgroundColor: TYPES_COLORS[t],
            color: '#fff',
        }
        overwrites.types[`avatar-${t}-hover`] = {
            '&:hover': {
                backgroundColor: LightenDarkenColor(TYPES_COLORS[t], 30),
                color: '#fff',
            }
        }
        overwrites.types[`avatar-${t}`] = {
            backgroundColor: TYPES_COLORS[t],
            color: '#fff',
            '&:hover': {
                backgroundColor: LightenDarkenColor(TYPES_COLORS[t], 30),
                color: '#fff',
            }
        }
    });

    return createMuiTheme(overwrites);
}