import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    makeStyles,
    TextField
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import useAxios from "axios-hooks";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {getLanguageLocally, isValidEmail, setToken} from "../../utils/profile";
import AuthContext from "../../contexts/auth";
import PasswordStrengthBar from "react-password-strength-bar";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));


const SignUpDialog = (props) => {

    const {open, setOpen}  = props;
    const { t } = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    return <Dialog
        open={open}
        onClose={handleClose}
        scroll='paper'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
    >
        <DialogTitle id="scroll-dialog-title">
            {t('landing.signup')}
        </DialogTitle>
        <DialogContent>
            <SignUp />
        </DialogContent>

    </Dialog>

}


export  const SignUp = () => {

    const classes = useStyles();

    const [email, setEmail] = useState("");
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [passwordMatch, setPasswordMatch] = useState(true);
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation();
    const auth = useContext(AuthContext);

    const [{data, loading, response}, doSubmit] = useAxios({
        url: "/api/auth/v1/users",
        method: "POST",
        validateStatus: (status) => status >= 200 && status < 500,
    }, {manual: true})

    const handleSubmit = () => {

        if (acceptTerms && isValidEmail(email) && passwordMatch) {
            doSubmit({
                data: {
                    email: email,
                    password: password,
                    language: getLanguageLocally()
                }
            })

        }
    }

    useEffect(() => {
        if (response && response.status === 400) {
            enqueueSnackbar(t("landing.emailUsed"), {
                variant: "error",
            })
        } else if (response && response.status === 201 && data) {
            setToken(data.token);
            auth.fetch();
        }
    }, [response, data]);

    useEffect(() => {
        setPasswordMatch(password === passwordConfirmation);
    }, [password, passwordConfirmation, setPasswordMatch])

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }
    const handlePasswordConfirmationChange = (event) => {
        setPasswordConfirmation(event.target.value);
    }
    const handleTermsChange = (event) => {
        setAcceptTerms(event.target.checked);
    }

    return <React.Fragment>
        <div className={classes.form}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        error={!!email && !isValidEmail(email)}
                        value={email}
                        disabled={loading}
                        onChange={handleEmailChange}
                        autoComplete="email"
                        label={t("landing.email")}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        value={password}
                        disabled={loading}
                        error={!passwordMatch}
                        onChange={handlePasswordChange}
                        label={t("landing.password")}
                        autoComplete="new-password"
                        type="password"
                    />
                    <PasswordStrengthBar password={password} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        value={passwordConfirmation}
                        onChange={handlePasswordConfirmationChange}
                        error={!passwordMatch}
                        disabled={loading}
                        label={t("landing.passwordConfirmation")}
                        autoComplete="new-password"
                        type="password"
                    />
                </Grid>
                <Grid item xs={12}>

                    <FormControlLabel
                        control={<Checkbox value="accept" checked={acceptTerms} onChange={handleTermsChange} color="primary" />}
                        label={<React.Fragment>
                            {t("landing.accept")}&nbsp;
                            <Link target="_blank" href="/privacy.html">{t("landing.terms")}</Link>
                        </React.Fragment>}
                    />
                </Grid>
            </Grid>
            <Button
                fullWidth
                disabled={loading || !acceptTerms || !isValidEmail(email) || !password || !passwordMatch}
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit}
            >{t("landing.doSignup")}
            </Button>
        </div>
    </React.Fragment>
}

export default SignUpDialog;