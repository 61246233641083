import {getCurrentToken} from "../utils/profile";

const axios = require('axios');

export const getAxiosHeaders = () => {
  const headers = {
    'Content-Type': 'application/json',
  };
  const token = getCurrentToken();
  if (token) {
    headers['Authorization'] = 'Token ' + token;
  }

  return headers;
}

class ProudAPIClass {
  constructor() {
    this.http = axios.create({

    })
  }

  get = (url, params) => {
    return this.http.get(
        url, {params: params, headers: getAxiosHeaders()}
        ).then(response => response.data)
  }

  post = (url, data) => {
    return this.http.post(url, data, {headers: getAxiosHeaders()}).then(response => response.data)
  }

  put = (url, data) => {
    return this.http.put(url, data, {headers: getAxiosHeaders()}).then(response => response.data)
  }

  patch = (url, data) => {
    return this.http.patch(url, data, {headers: getAxiosHeaders()}).then(response => response.data)
  }

  delete = (url) => {
    return this.http.delete(url, {headers: getAxiosHeaders()}).then(response => response.data)
  }

}

const ProudAPI = new ProudAPIClass();
export default ProudAPI;
