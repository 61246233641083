import findAndReplace from 'mdast-util-find-and-replace';
const u = require('unist-builder')

const CROSS_LINK_REGEX = /@\{(?<title>.*?)\}\[(?<id>.*?)\]/ig;

function crossLink() {

    function transform(tree) {
        findAndReplace(tree, CROSS_LINK_REGEX, replace);
    }

    return transform;
}

function replace(match, title, id) {
    return u('crosslink', {
        url: new URL(`/activity/${id}`, document.baseURI).href,
        title: title,
    }, [u('text', title)]);
}


export default crossLink;