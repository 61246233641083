import {ButtonGroup, makeStyles, Tooltip} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import LanguageIcon from '@material-ui/icons/Language';
import React from "react";
import {useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row-reverse'
        },

    },
    noBorder: {
        border: '0 !important',
    },
    colorless: {
        color: theme.palette.colorless.main,
    }
}));

export const ItemCardControls = (props) => {

    const { item, edit, remove} = props;
    const { t } = useTranslation();
    const classes = useStyles();

    return <ButtonGroup variant="text" color="primary" className={classes.root}>

        {item.link ?

            <Tooltip title={t("itemCard.open")} placement="top" >
                <Button target="_blank" href={item.link} aria-label={t("itemCard.open")} className={clsx(classes.noBorder, classes.colorless)}>
                    <LanguageIcon fontSize="small"/>
                </Button>
            </Tooltip>

            : null}

        <Tooltip title={t("itemCard.edit")} placement="top" >
            <Button aria-label={t("itemCard.edit")} onClick={edit} className={clsx(classes.noBorder, classes.colorless)}>
                <EditIcon fontSize="small"/>
            </Button>
        </Tooltip>

        <Tooltip title={t("itemCard.remove")} placement="top">
            <Button aria-label={t("itemCard.remove")} onClick={remove} className={clsx(classes.noBorder, classes.colorless)}>
                <DeleteIcon fontSize="small"/>
            </Button>
        </Tooltip>

    </ButtonGroup>
}