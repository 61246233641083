import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from '@material-ui/icons/Settings';
import {
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Grow, InputAdornment, ListItemIcon,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    TextField, Tooltip
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import React, {useContext, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import LanguageSwitcher from "./language_switcher";
import {useTranslation} from "react-i18next";
import AuthContext from "../../contexts/auth";
import {useHistory} from "react-router-dom";
import Box from "@material-ui/core/Box";
import useAxios from "axios-hooks";
import {getAxiosHeaders} from "../../api/client";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {LightenDarkenColor} from "lighten-darken-color";
import {TYPES_COLORS} from "../../theme";
import ShareIcon from '@material-ui/icons/Share';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    },
    transparentBar: {
        width: 'auto',
        position: 'absolute',
        backgroundColor: 'transparent',
            boxShadow: 'none',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    popper: {
        zIndex: theme.zIndex.drawer + 2,
    },
    popperBox: {
        backgroundColor: theme.palette.background.default,
    },
    copyIcon: {
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.main,
        },
        '&:active': {
            color: LightenDarkenColor(theme.palette.primary.main, 30),
        }
    }
}))

export const TopMenu = () => {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState();
    const [share, setShare] = useState(false);
    const { t } = useTranslation();
    const auth = useContext(AuthContext);
    const history = useHistory();

    const [{response: exportResponse, loading}, doExport] = useAxios({
        url: '/api/memory/v1/heartbeats/export',
        responseType: 'blob',
        headers: getAxiosHeaders(),
    }, {useCache: false, manual: true});

    const handleExport = () => {
        doExport();
    }

    const handleShare = () => {
        setShare(true);
    }

    const handleCloseShare = (event) => {
        setShare(false);
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const handleClose = (event) => {
        setAnchorEl(null);
    }
    const handleLogout = () => {
        if (history) {
            history.push("/");
        }
        auth.logout();
    }

    const [copied, setCopied] = useState(false);
    const copiedLeaveDelay = copied ? 1200 : 0;
    const copiedText = t(copied ? "topMenu.shareDialog.copied" : "topMenu.shareDialog.copy");
    const shareLink = `${window.location.origin}/shared/${auth.user.share_id}`;

    const handleLinkCopy = (text, result) => {
        setCopied(true);
    }

    useEffect(() => {
        if (!exportResponse || exportResponse.status !== 200) return
        let filename = "";
        const disposition = exportResponse.headers['content-disposition'];
        if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }
        const downloadUrl = window.URL.createObjectURL(new Blob([exportResponse.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }, [exportResponse])

    return <AppBar className={classes.transparentBar}>
        <Toolbar className={classes.toolbar}>
            <div onMouseLeave={handleClose}>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    className={classes.menuButton}
                    onMouseEnter={handleClick}
                    onClick={handleClick}
                >
                    <SettingsIcon />
                </IconButton>

                <Popper
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    className={classes.popper}
                    role={undefined}

                    placement="bottom-end"
                    transition
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top ' : 'center bottom' }}
                        >
                            <Paper className={classes.popperBox}>
                                <Box px={2} pt={1}><LanguageSwitcher /></Box>
                                <MenuList>
                                    <MenuItem onClick={handleShare}>
                                        <ListItemIcon>
                                            <ShareIcon />
                                        </ListItemIcon>
                                        {t('topMenu.share')}
                                    </MenuItem>
                                    <MenuItem onClick={handleExport} disabled={loading}>
                                        <ListItemIcon>
                                            <CloudDownloadIcon />
                                        </ListItemIcon>
                                        {t('topMenu.export')}
                                    </MenuItem>
                                    <MenuItem onClick={handleLogout}>
                                        <ListItemIcon>
                                            <ExitToAppIcon />
                                        </ListItemIcon>
                                        {t('topMenu.logout')}
                                    </MenuItem>
                                </MenuList>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
            <Dialog open={share} onClose={handleCloseShare} fullWidth maxWidth="sm">
                <DialogTitle id="form-dialog-title">{t('topMenu.shareDialog.title')}</DialogTitle>
                <DialogContent>
                    <TextField
                        readOnly
                        margin="dense"
                        value={shareLink}
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <CopyToClipboard text={shareLink} onCopy={handleLinkCopy} className={classes.copyIcon}>
                                    <Tooltip title={copiedText} placement="right" leaveDelay={copiedLeaveDelay}>
                                        <FileCopyOutlinedIcon fontSize="small"/>
                                    </Tooltip>
                                </CopyToClipboard>
                            </InputAdornment>,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseShare} color="primary">
                        {t('topMenu.shareDialog.close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Toolbar>
    </AppBar>
}

export default TopMenu;