import HeartBeats from "../heartbeats/heartbeat";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TagsCloud from "../feed/tags";
import CompetenciesCloud from "../feed/competencies";
import React, {useEffect, useState} from "react";
import {Chip, Hidden, InputAdornment, TextField} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {useHistory, useLocation} from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import useDebounce from "../../utils/debounce";
const _ = require("lodash");

const CheatsheetsList = () => {

    const location = useLocation();
    const history = useHistory();

    const urlParams = new URLSearchParams(location.search);
    const { t } = useTranslation();
    const [tags, setTags] = useState(urlParams.getAll('tag', []));
    const [query, setQuery] = useState(urlParams.get('query') || "");

    const handleAddTags = (tag) => {
        if (tags.indexOf(tag) === -1) {
            setTags(tags ? _.concat(tags, tag) : [tag]);
        }
    }

    const handleDeleteTags = (tag, index) => {
        setTags(_.filter(tags, (value) => value !== tag))
    }

    const debouncedQuery = useDebounce(query);

    const updatePageAddress = () => {
        const search = new URLSearchParams();
        if (!_.isEmpty(debouncedQuery)) {
            search.append('query', debouncedQuery);
        }
        if (tags) {
            tags.map((tag) => search.append('tag', tag))
        }
        const newLocation = {
            pathname: "/cheatsheets",
            search: search.toString(),
        }
        if (location.pathname !== newLocation.pathname || location.search !== newLocation.search) {
            history.push(newLocation);
        }
    };

    useEffect(() => {
        updatePageAddress(); // eslint-disable-next-line
    }, [tags, debouncedQuery])

    return <Grid container spacing={4}>
        <Grid item xs={9}>
            <Typography variant="h4">{t('drawer.menu.cheatsheets')}</Typography>
        </Grid>
        <Grid item md={3} xs={12}>

            <TextField
                label={t("activitiesList.search")}
                value={query}
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                    endAdornment:<InputAdornment position="end">
                        {query ?
                            <CloseIcon fontSize="small" onClick={() => setQuery("")} /> : " "
                        }
                    </InputAdornment>,
                }}
                onChange={(event) => setQuery(event.target.value)} />
        </Grid>

        <Grid item md={9} xs={12}>
            {!_.isEmpty(tags) && <Box my={2}>{tags.map( (tag) => {
                return <Box component="span" p={0.5} key={tag}>
                    <Chip label={tag} variant="outlined" onClick={() => handleDeleteTags(tag)} onDelete={() => handleDeleteTags(tag)} />
                </Box>
            })}</Box>}
            <HeartBeats type="cheatsheet" done={1} forceCard={true} 
                        tags={tags}query={debouncedQuery} setTags={handleAddTags} hideRating={true}/>
        </Grid>
        <Hidden mdDown>
            <Grid item xs={3}>
                <TagsCloud addTag={handleAddTags} deleteTag={handleDeleteTags} tags={tags}/>
                <p>&nbsp;</p>
                <CompetenciesCloud />
            </Grid>
        </Hidden>
    </Grid>

}

export default CheatsheetsList;