import {Badge, Card, CardContent, CardHeader, Chip, CircularProgress, makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import TagsContext from "../../contexts/tags";

const _ = require("lodash");

const useStyles = makeStyles((theme) => ({
    tag: {
        margin: theme.spacing(1),
    },
    badge: {
        color: theme.palette.common.white
    }
}));

const Tag = ({tag, count, addTag, deleteTag, active}) => {
    const classes = useStyles();
    const actions = active ? { onClick: () => deleteTag(tag) } : { onClick: () => addTag(tag) }
    return <Badge badgeContent={count} color="primary" className={classes.tag} classes={{
        colorPrimary: classes.badge
    }}>
        <Chip variant={active ? "outlined" : "default"} label={tag} {...actions}/>
    </Badge>
}

const TagsCloud = (props) => {

    const tagsContext = useContext(TagsContext);

    const {t} = useTranslation();

    const {addTag, deleteTag, tags} = props;

    return <Card variant="outlined" >
        <CardHeader title={t('tagsCloud.title')} />
        <CardContent>
            {tagsContext.loading ? <CircularProgress /> :
                <Box p={1}>
                    {tagsContext.data && !_.isEmpty(tagsContext.data.tags) ? <React.Fragment>
                        {
                            _.map(tagsContext.data.tags, (count, tag) => {
                                return <Tag key={tag} tag={tag} count={count}
                                            addTag={addTag} deleteTag={deleteTag}
                                            active={!_.isEmpty(tags) && tags.indexOf(tag) > -1}/>
                            })
                        } {
                            _.size(tagsContext.data.tags) < tagsContext.data.count ?
                                <Link component="button" onClick={() => tagsContext.setSize(null)}>More tags</Link>
                                : null}
                    </React.Fragment>
                    : <Typography>{t('tagsCloud.noTags')}</Typography>}
                </Box>
            }
        </CardContent>
    </Card>
}

export default TagsCloud;