import React, { useEffect, useState} from "react";
import {
    Avatar, Chip, fade, Hidden, InputAdornment, InputBase,
    makeStyles,
    Tab,
    Tabs, TextField,
    Tooltip,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import {BEATS} from "./beats";
import {Link as RouterLink, useHistory, useLocation, useParams} from "react-router-dom";
import {grey} from "@material-ui/core/colors";
import clsx from "clsx";
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import {AvatarGroup} from "@material-ui/lab";
import CompetenciesCloud from "./competencies";
import TagsCloud from "./tags";
import Box from "@material-ui/core/Box";
import HeartBeats from "../heartbeats/heartbeat";
import {LightenDarkenColor} from "lighten-darken-color";
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from "@material-ui/icons/Close";
import useDebounce from "../../utils/debounce";

const _ = require("lodash");

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    activeFilter: {
        cursor: 'pointer',
        color: theme.palette.getContrastText(grey[900]),
        backgroundColor: grey[900],
        '&:hover': {
            color: theme.palette.getContrastText(grey[800]),
            backgroundColor: grey[800],
        }
    },
    typeFilter: {
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.getContrastText(grey[600]),
            backgroundColor: grey[600],
        }
    },
    list: {
        width: '100%'
    },
    filters: {
        display: 'flex',
        flexGrow: 1,
        paddingLeft: '8px !important'
    },
    ownFilter: {
       marginRight: theme.spacing(2),
    },
    ownFilterAvatar: {
        cursor: 'pointer',
        color: theme.palette.colorless.main,
        backgroundColor: 'transparent',
        '&:hover': {
            color: LightenDarkenColor(theme.palette.secondary.main, -15),
        }
    },
    ownFilterActiveAvatar: {
        cursor: 'pointer',
        color: theme.palette.secondary.main,
        backgroundColor: 'transparent',
        '&:hover': {
            color: LightenDarkenColor(theme.palette.secondary.main, -15),
        }
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
    pointer: {
        cursor: 'pointer',
    },
    ...theme.types
}));


const routes = ["/feed/roadmap", "/feed/behind"];

const HeartBeatsFeed = () => {

    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { direction } = useParams();
    const done = direction === 'behind' ? 1 : 0;
    const { t } = useTranslation();

    const urlParams = new URLSearchParams(location.search);

    const [type, setType] = useState(urlParams.get('type'));
    const [query, setQuery] = useState(urlParams.get('query') || "");
    const [ownContent, setOwnContent] = useState(urlParams.get('own') === '1' ? true : null);
    const [tags, setTags] = useState(urlParams.getAll('tag'));

    const debouncedQuery = useDebounce(query);

    const updatePageAddress = () => {
        const search = new URLSearchParams();
        let pathname = routes[done];
        if (type) {
            search.append('type', type);
        }
        if (!_.isEmpty(debouncedQuery)) {
            search.append('query', debouncedQuery);
        }
        if (ownContent) {
            search.append('own', '1');
        }
        if (tags) {
            tags.map((tag) => search.append('tag', tag))
        }
        const newLocation = {
            pathname: pathname,
            search: search.toString(),
        }
        if (location.pathname !== newLocation.pathname || location.search !== newLocation.search) {
            history.push(newLocation);
        }
    };

    useEffect(() => {
        document.title = `ipsly: ${done ? "behind" : "roadmap" }`;
    }, [done])

    useEffect(() => {
        updatePageAddress(); // eslint-disable-next-line
    }, [type, done, ownContent, tags, debouncedQuery])

    const toggleOwnFilter = (event) => {
        setOwnContent(ownContent ? null : true);
    }

    const toggleTypeFilter = (key) => {
        // This is for future, when we will support multiple types for filtering
        // if (types.indexOf(key) === -1) {
        //     setTypes(_.union(types, [key]));
        // } else {
        //     setTypes(_.filter(types, (el) => el !== key));
        // }
        setType(key === type ? null : key);
    }

    const handleAddTags = (tag) => {
        if (tags.indexOf(tag) === -1) {
            setTags(tags ? _.concat(tags, tag) : [tag]);
        }
    }

    const handleDeleteTags = (tag, index) => {
        setTags(_.filter(tags, (value) => value !== tag))
    }

    return <Grid container spacing={4}>
        <Grid item sm={6} xs={12}>
            <Tabs
                value={done}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab
                    label={t('activitiesList.behind')}
                    // icon={<Tooltip title={<Trans>Behind</Trans>} placement="top"><ArrowBackIcon /></Tooltip>}
                     value={1}
                     component={RouterLink}
                     to="/feed/behind"/>
                <Tab
                    label={t('activitiesList.roadmap')}
                    // icon={<Tooltip title={<Trans>Ahead</Trans>} placement="top"><ForwardIcon /></Tooltip>}
                    value={0}
                    component={RouterLink}
                    to="/feed/roadmap"/>
            </Tabs>
        </Grid>
        <Grid item md={3} xs={12} className={classes.filters}>

            <AvatarGroup max={10} className={classes.ownFilter}>
                <Tooltip title={t("activitiesList.own")} placement="top" >
                    <Avatar
                        onClick={toggleOwnFilter}
                        alt={t("activitiesList.own")}
                        className={clsx({
                            [classes.ownFilterAvatar]: !ownContent,
                            [classes.ownFilterActiveAvatar]: ownContent,
                            // [classes.typeFilter]: !ownContent,
                            // [classes.activeFilter]: ownContent
                        })}
                    ><EmojiPeopleIcon fontSize="large"/></Avatar>
                </Tooltip>
            </AvatarGroup>

            <AvatarGroup max={10}>
                {_.map(_.values(BEATS), ({key, title, icon, enabled, filter}) =>
                    enabled && filter ? <Tooltip title={t(title)} placement="top" key={key}>
                        <Avatar
                            onClick={() => toggleTypeFilter(key)}
                            alt={title}
                            className={clsx(classes[`avatar-${key}-hover`], {
                                [classes[`avatar-${key}`]]: type === key,
                                [classes.typeFilter]: type !== key,
                                [classes.activeFilter]: type === key
                            })}
                        >{icon()}</Avatar></Tooltip> : null
                )}
            </AvatarGroup>
        </Grid>
        <Grid item md={3} xs={12}>

            <TextField
                label={t("activitiesList.search")}
                value={query}
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                    endAdornment:<InputAdornment position="end">
                        {query ?
                            <CloseIcon fontSize="small" onClick={() => setQuery("")} className={classes.pointer}/> : " "
                        }
                        </InputAdornment>,
                }}
                onChange={(event) => setQuery(event.target.value)} />
        </Grid>

        <Grid item md={9} xs={12}>
            {!_.isEmpty(tags) && <Box my={2}>{tags.map( (tag) => {
                return <Box component="span" p={0.5} key={tag}>
                    <Chip label={tag} variant="outlined" onClick={() => handleDeleteTags(tag)} onDelete={() => handleDeleteTags(tag)} />
                </Box>
            })}</Box>}
            <HeartBeats type={type} done={done} own={ownContent} tags={tags} query={debouncedQuery} setTags={handleAddTags}/>
        </Grid>
        <Hidden mdDown>
            <Grid item xs={3}>
                <TagsCloud addTag={handleAddTags} deleteTag={handleDeleteTags} tags={tags} />
                <p>&nbsp;</p>
                <CompetenciesCloud />
            </Grid>
        </Hidden>
    </Grid>
}


export default HeartBeatsFeed;