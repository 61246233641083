import Grid from "@material-ui/core/Grid";
import {makeStyles, Switch} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
}));

const LandingLanguageSwitcher = (props) => {

    const {en, ru, lang, setLang} = props;
    const classes = useStyles();

    const handleChange = (event) => {
        setLang(event.target.checked ? "ru" : "en");
    }


    return <Grid component="label" container spacing={1} className={classes.root}>
            <Grid item>{en || "EN"}</Grid>
            <Grid item>
                <Switch checked={lang === "ru"} onChange={handleChange}  />
            </Grid>
            <Grid item>{ru || "RU"}</Grid>
        </Grid>
}

export default LandingLanguageSwitcher;