import React, {useState} from 'react';
import {setSoul} from "../../api/profile";
import Button from "@material-ui/core/Button";
import {Backdrop, CircularProgress, makeStyles, useMediaQuery, useTheme} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {useTranslation} from "react-i18next";
import LanguageSwitcher from "../common/language_switcher";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        textAlign: 'center',
    },
    soul: {
        filter: "drop-shadow(-1px 2px 3px rgba(0,0,0,.7))",
    }
}));


export const Soul = (props) => {

    const classes = useStyles();

    return <img src={`/souls/` + props.soul + `/` + props.state + `.png`}
                alt={props.soul + '.' + props.state}
                width={['dead', 'sad'].indexOf(props.state) > -1 ? props.width : "auto"}
                height={['dead', 'sad'].indexOf(props.state) > -1 ? "auto" : props.width}
                className={classes.soul}
                style={{opacity: props.disabled ? 0.5 : 1}}
    />;
};

export const SoulSwitchWidget = (props) => {

    // const [current, setCurrent] = useState('blue_hoodie');
    const current = 'blue_hoodie';
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    const { t } = useTranslation();

    const handleSubmit = () => {
        setLoading(true)
        setSoul(current).then((data) => {
            if (!data.error) {
                props.onUpdate();
            }
        })
    }

    if (loading) {
        return <Backdrop open={true}>
            <CircularProgress  />
        </Backdrop>
    } else {

        return <Box className={classes.paper}>

            <Box mx={10}>
                <Soul soul='blue_hoodie' state='zen' width={200} />
            </Box>
            <Box mx={10} my={2}>
                <Typography variant={isMobile ? "subtitle1" : "h4"}>{t('signupFinish.welcome')}</Typography>
            </Box>
            <Box mx={10}>
                <Typography variant={isMobile ? "subtitle1" : "h5"}>{t('signupFinish.lang')}</Typography>
            </Box>
            <Box><LanguageSwitcher en="English" ru="Русский" /></Box>

            <Box m={2}>
                <Button variant="contained"  onClick={handleSubmit} color="primary" size="large">
                    {t("signupFinish.submit")}
                </Button>
            </Box>
        </Box>

    }
}