import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import {TableBody, TableCell, TableHead, TableRow, Tooltip} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {CopyToClipboard} from "react-copy-to-clipboard";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles( (theme) => ({
    listItem: {
        marginTop: theme.spacing(1),
    },
    paragraph: {
        marginBottom: theme.spacing(2),
    },
    header: {
        marginTop: theme.spacing(2),
    },
    copyCodeWrapper: {
        width: '100%',
        position: 'relative',
    },
    copyIcon: {
        fontSize: '1rem',
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        '&:hover': {
            color: theme.palette.primary.light,
        }
    }
}));

export function MarkdownParagraph(props) {
    const classes = useStyles();
    return <Typography className={classes.paragraph}>{props.children}</Typography>
}

export const MarkdownHeading = (props) => {
    const classes = useStyles();
    let variant;
    switch (props.level) {
        case 1:
            variant = "h5";
            break;
        case 2:
            variant = "h6";
            break;
        case 3:
            variant = "subtitle1";
            break;
        case 4:
            variant = "subtitle2";
            break;
        default:
            variant = "h6";
            break;
    }
    return <Typography className={classes.header} gutterBottom variant={variant}>{props.children}</Typography>
};

export const MarkdownListItem = (props) => {

    const classes = useStyles();

    return (
        <li className={classes.listItem}>
            <Typography component="span">{props.children}</Typography>
        </li>
    );
};

export function MarkdownTable(props) {
    return (
        <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">{props.children}</Table>
        </TableContainer>
    );
}

export function MarkdownTableCell(props) {
    return <TableCell><Typography>{props.children}</Typography></TableCell>
}

export function MarkdownTableRow(props) {
    return <TableRow>{props.children}</TableRow>
}

export function MarkdownTableBody(props) {
    return <TableBody>{props.children}</TableBody>
}

export function MarkdownTableHead(props) {
    return <TableHead>{props.children}</TableHead>
}

export const MarkdownLink = ({children, url, href}) => {
    return <Link target="_blank" href={url ? url : href}>{children}</Link>
}

const MarkdownCrossLink = ({title, url}) => {
    return <Link target="_blank" href={url}>{title}</Link>
}

const CodeBlock = ({language, value}) => {
    const [copied, setCopied] = useState(false);
    const copiedLeaveDelay = copied ? 1200 : 0;
    const classes = useStyles();

    const {t} = useTranslation();

    const handleLinkCopy = (text, result) => {
        setCopied(true);
    }

    useEffect(() => {
        if (copied) {
            setTimeout(() => setCopied(false), 2000)
        }
    }, [copied])
    const copiedText = t(copied ? "itemCard.codeCopied" : "itemCard.codeCopy");

    if (value) {
        return <div className={classes.copyCodeWrapper}>
            <CopyToClipboard text={value} onCopy={handleLinkCopy}  className={classes.copyIcon}>
                <Tooltip title={copiedText} placement="right" leaveDelay={copiedLeaveDelay}>
                    <FileCopyOutlinedIcon fontSize="small" style={{cursor: 'pointer'}}/>
                </Tooltip>
            </CopyToClipboard>
            <SyntaxHighlighter language={language} children={value} showLineNumbers/>
        </div>
    }
    return <pre><code>{value}</code></pre>
}

export const markdownRenderers = {
    heading: MarkdownHeading,
    paragraph: MarkdownParagraph,
    link: MarkdownLink,
    crosslink: MarkdownCrossLink,
    listItem: MarkdownListItem,
    table: MarkdownTable,
    tableHead: MarkdownTableHead,
    tableBody: MarkdownTableBody,
    tableRow: MarkdownTableRow,
    tableCell: MarkdownTableCell,
    code: CodeBlock
};

export const textRenderers = {

    heading: MarkdownParagraph,
    paragraph: MarkdownParagraph,
    link: MarkdownParagraph,
    crosslink: MarkdownCrossLink,
    listItem: MarkdownParagraph,
    table: MarkdownParagraph,
    tableHead: MarkdownParagraph,
    tableBody: MarkdownParagraph,
    tableRow: MarkdownParagraph,
    tableCell: MarkdownParagraph,
    code: MarkdownParagraph
};