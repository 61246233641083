import clsx from "clsx";
import {
  ClickAwayListener,
  Grow,
  Hidden,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles, Paper,
  Popper,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import AuthContext from "../../contexts/auth";
import {Soul} from "../profile/soul";
import List from "@material-ui/core/List";
import Drawer from "@material-ui/core/Drawer";
import React, {useContext, useEffect} from "react";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import TopMenu from "./top_menu";
import {BEATS} from "../feed/beats";
import {useTranslation} from "react-i18next";
import {Link, useLocation} from "react-router-dom";
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import useAxios from "axios-hooks";
import {getAxiosHeaders} from "../../api/client";
import {getLanguageLocally} from "../../utils/profile";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    position: 'relative',
  },
  drawerPaper: {
    backgroundColor: theme.palette.grey[100],
  },
  drawerOpen: {
    width: drawerWidth,
  },
  drawerClose: {
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  soul: {
    minHeight: '175px',
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(0),
    paddingTop: theme.spacing(1)
  },
  pointer: {
    cursor: 'pointer',
  },
  quote: {
    zIndex: theme.zIndex.drawer + 2,
    marginLeft: "-64px",
    marginTop: "85px"
  },
  quotePaper: {
    boxShadow: theme.shadows[10],
    borderRadius: theme.spacing(2),
    borderTopLeftRadius: "0px",
    maxWidth: "50vw",
    minWidth: "100px",
  },
  quoteTitle: {
    position: "absolute",
    right: "10px",
    margin: theme.spacing(0.5),
  }
}));


const PJDrawer = (props) => {

  const theme = useTheme();
  const classes = useStyles();
  const $is_wide = useMediaQuery(theme.breakpoints.up('sm'));
  const [showMobile, setShowMobile] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showQuote, setShowQuote] = React.useState(false);

  const handleDrawerOpen = () => {
    setShowMobile(true);
  };

  const handleDrawerClose = () => {
    setShowMobile(false);
  };

  const open = $is_wide || showMobile;

  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const location = useLocation();
  const language = getLanguageLocally();

  const [{data}, getTodayQuote] = useAxios({
    url: "/api/muse/v1/quotes/today",
    method: "GET",
    headers: getAxiosHeaders(),
    validateStatus: (status) => status >= 200 && status < 500,
  }, {manual: true, useCache: true})

  const handleSoulClick = (event) => {
    if ($is_wide) {
      setAnchorEl(event.currentTarget);
      getTodayQuote();
    }
  }

  useEffect(() => {
    if (data) {
      setShowQuote(true);
    }
  }, [data])


  return (
    <div>

      <TopMenu />

      <Hidden smUp>

        <AppBar
          position="absolute"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon/>
            </IconButton>
            <Typography variant="h6" noWrap>
              ipsly.com
            </Typography>
          </Toolbar>
        </AppBar>
      </Hidden>
      <Drawer
        variant={$is_wide ? "permanent" : "temporary"}
        open={open}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx(classes.drawerPaper, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Hidden smUp>
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
            </IconButton>
          </div>
          <Divider/>
        </Hidden>

        <Box display="flex" justifyContent="center" alignItems="center" my={5} className={classes.soul}>
            <Box onClick={handleSoulClick} className={classes.pointer}>
              <Soul soul='blue_hoodie' state={auth.user.soul.state.key} width={200}/>
            </Box>
        </Box>
        <Divider/>

        <List>
          <ListItem selected={location.pathname.indexOf("/feed") === 0} button to="/feed" component={Link}>
            <ListItemIcon><DynamicFeedIcon /></ListItemIcon>
            <ListItemText primary={t("drawer.menu.feed")} />
          </ListItem>
        
          <ListItem button selected={location.pathname.indexOf("/cheatsheets") === 0} to="/cheatsheets"  component={Link}>
            <ListItemIcon>{BEATS.cheatsheet.icon()}</ListItemIcon>
            <ListItemText primary={t("drawer.menu.cheatsheets")} />
          </ListItem>

          {/*<ListItem button to="/tags"  component={Link}>*/}
          {/*  <ListItemIcon><LocalOfferIcon /></ListItemIcon>*/}
          {/*  <ListItemText primary={t("drawer.menu.tags")} />*/}
          {/*</ListItem>*/}
        </List>

      </Drawer>
      <Popper open={showQuote} anchorEl={anchorEl} className={classes.quote} placement="right-start" transition>
        {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={() => setShowQuote(false)}>
            <Grow {...TransitionProps} style={{ transformOrigin: '0 0 0' }} timeout={350}>
                <Paper className={classes.quotePaper} variant="outlined">
                    <h6 className={classes.quoteTitle}>{t("drawer.quote")}</h6>
                    <Box p={1} pt={3} textAlign="right">
                      <Typography variant="subtitle1" align="left">{data && language === "en" ? data.text_en : data.text_ru }</Typography>
                      <Typography variant="caption">&copy; {data && language === "en" ? data.author_en : data.author_ru}</Typography>
                    </Box>
                  </Paper>
            </Grow>
            </ClickAwayListener>
        )}
      </Popper>
    </div>)
}

export default PJDrawer;
