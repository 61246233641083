import {makeStyles, Modal, Paper, Tooltip} from "@material-ui/core";
import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import HeartBeatForm from "../forms/base";
import {TYPES_COLORS} from "../../theme";


const useStyles = makeStyles((theme) => ({
    formPaper: {
        position: 'absolute',
        width: '35%',
        backgroundColor: theme.palette.background.paper,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            height: '90vh',
            maxHeight: '90vh',
        },
        height: '80vh',
        maxHeight: '80vh',
        outline: 'none',
        transition: 'border-color 0.3s ease-out'
    },
    formContent: {
        padding: theme.spacing(2),
        height: '100%',
    }
}));


const ActivityForm = (props) => {
    const {
        initialType,
        Form,
        open,
        handleClose,
    } = props;

    const classes = useStyles();

    const [type, setType] = useState(initialType || "");

    return (
        <Modal
            open={open}
            onClose={handleClose}
            disableBackdropClick={true}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Paper className={classes.formPaper} variant="outlined" style={{
                borderRadius: "4px",
                border: '0',
                outline: 0,
                borderLeft: `8px solid ${type ? TYPES_COLORS[type] : 'rgba(0, 0, 0, 0.12)'}`
            }}>
                <Box position="relative">
                    <Box position="absolute" right="0px">
                        <Tooltip title="close" placement="left">
                            <IconButton aria-label="Close" onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>

                { Form ? <Form {...props}/> : <HeartBeatForm type={type} setType={setType} linkField={true} {...props} />}
            </Paper>
        </Modal>
    )
}

export default ActivityForm;